import ApiRequest from "../util/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Sender {
    update = async (id, body) => {
        return await ApiRequest.set(`/v1/sender/${id}`, "PUT", body);
    }
    updatePassword = async (id, body) => {
        return await ApiRequest.set(`/v1/sender/update-password/${id}`, "PUT", body);
    }
}