import L from 'leaflet';
import App from "./App";
import {useLeaflet} from "react-leaflet";
import {useEffect, useState} from "react";
import '@elfalem/leaflet-curve';


export default function FlightCluster(props) {
    const {map} = useLeaflet();
    const {flightClusterData} = props
    const indexClass = 'flight_key_' + props.indexClass;
    const [pathColor, setPathColor] = useState('')


    const SnakeFlight = () => {

        L.Polyline.include({

            // Hi-res timestamp indicating when the last calculations for vertices and
            // distance took place.
            _snakingTimestamp: 0,

            // How many rings and vertices we've already visited
            // Yeah, yeah, "rings" semantically only apply to polygons, but L.Polyline
            // internally uses that nomenclature.
            _snakingRings: 0,
            _snakingTailRings: 0,
            _snakingVertices: 0,
            _snakingTailVertices: 0,

            // Distance to draw (in screen pixels) since the last vertex
            _snakingDistance: 0,
            _snakingTailDistance: 0,

            // Flags
            _snakingIn: false,
            _snakingOut: false,


            /// TODO: accept a 'map' parameter, fall back to addTo() in case
            /// performance.now is not available.
            snakeIn: function () {

                if (this._snakingIn || this._snakingOut) {
                    return;
                }

                if (!('performance' in window) ||
                    !('now' in window.performance) ||
                    !this._map) {
                    return;
                }

                this._snakingIn = true;
                this._snakingTime = performance.now();
                this._snakingVertices = this._snakingRings = this._snakingDistance = 0;

                if (!this._snakeLatLngs) {
                    this._snakeLatLngs = L.LineUtil.isFlat(this._latlngs) ?
                        [this._latlngs] :
                        this._latlngs;
                }

                // Init with just the first (0th) vertex in a new ring
                // Twice because the first thing that this._snake is is chop the head.
                this._latlngs = [[this._snakeLatLngs[0][0], this._snakeLatLngs[0][0]]];

                this._update();
                this.fire('snakeInStart', this._latlngs[0][0]);
                this._snake();

                return this;
            },

            snakeOut: function () {

                if (this._snakingOut) {
                    return;
                }

                if (!('performance' in window) ||
                    !('now' in window.performance) ||
                    !this._map) {
                    return;
                }

                this._snakingOut = true;
                this._snakingTime = performance.now();
                this._snakingTailVertices = this._snakingTailRings = this._snakingTailDistance = 0;

                if (!this._snakeLatLngs) {
                    this._snakeLatLngs = L.LineUtil.isFlat(this._latlngs) ?
                        [this._latlngs] :
                        this._latlngs;
                }

                if (!this._snakingIn) {
                    //need to do a deep copy
                    let tempArray = [];
                    let keys = Object.keys(this._snakeLatLngs);
                    for (let i in keys) {
                        tempArray.push([]);
                        this._snakeLatLngs[tempArray.length - 1].forEach(function (entry) {
                            tempArray[tempArray.length - 1].push([entry.lat, entry.lng]);
                        });
                    }
                    this._latlngs = tempArray;
                }

                this._update();
                this.fire('snakeOutStart', this._latlngs[0][0]);
                // Avoid concurrent calls to _snake
                if (!this._snakingIn) {
                    this._snake();
                }

                return this;
            },

            _snake: function () {
                // If polyline has been removed from the map stop _snakeForward
                if (!this._map) return;

                let now = performance.now();
                let timeDiff = now - this._snakingTime;	// In milliseconds
                timeDiff = (timeDiff === 0 ? 0.001 : timeDiff); // avoids low time resolution issues in some browsers
                this._snakingTime = now;

                // Chop the head from the previous frame
                if (this._snakingIn) {
                    this._latlngs[this._snakingRings].pop();
                }
                // Chop the tail from the previous frame
                if (this._snakingOut) {
                    this._latlngs[this._snakingTailRings].shift();
                }

                if (this._snakingIn) {
                    this._snakeHeadForward(timeDiff);
                }
                if (this._snakingOut) {
                    this._snakeTailForward(timeDiff);
                }

                this.setLatLngs(this._latlngs);
                // Animate only if snake in moving
                if (this._snakingIn || this._snakingOut) {
                    L.Util.requestAnimFrame(this._snake, this);
                }

                return this;
            },

            _snakeHeadForward: function (timeDiff) {
                let forward = timeDiff * this.options.snakingSpeed / 1000;	// In pixels

                // Calculate distance from current vertex to next vertex
                let currPoint = this._map.latLngToContainerPoint(
                    this._snakeLatLngs[this._snakingRings][this._snakingVertices]);
                let nextPoint = this._map.latLngToContainerPoint(
                    this._snakeLatLngs[this._snakingRings][this._snakingVertices + 1]);

                let distance = currPoint.distanceTo(nextPoint);

                //console.log('Distance head to next point:', distance, '; Now at: ', this._snakingDistance, '; Must travel forward:', forward, '_snakingTime', this._snakingTime, '_snakingVertices', this._snakingVertices);
                //console.log('Snake vertices: ', this._latlngs,';this._snakeLatLngs',this._snakeLatLngs);

                while (this._snakingDistance + forward > distance) {
                    // Jump to next vertex
                    this._snakingVertices++;
                    this._latlngs[this._snakingRings].push(this._snakeLatLngs[this._snakingRings][this._snakingVertices]);

                    if (this._snakingVertices >= this._snakeLatLngs[this._snakingRings].length - 1) {
                        if (this._snakingRings >= this._snakeLatLngs.length - 1) {
                            return this._snakeInEnd();
                        } else {
                            this._snakingVertices = 0;
                            this._snakingRings++;
                            this._latlngs[this._snakingRings] = [
                                this._snakeLatLngs[this._snakingRings][this._snakingVertices]
                            ];
                        }
                    }

                    this._snakingDistance -= distance;
                    currPoint = this._map.latLngToContainerPoint(
                        this._snakeLatLngs[this._snakingRings][this._snakingVertices]);
                    nextPoint = this._map.latLngToContainerPoint(
                        this._snakeLatLngs[this._snakingRings][this._snakingVertices + 1]);
                    distance = currPoint.distanceTo(nextPoint);
                }

                this._snakingDistance += forward;

                let percent = this._snakingDistance / distance;

                let headPoint = nextPoint.multiplyBy(percent).add(
                    currPoint.multiplyBy(1 - percent)
                );

                // Put a new head in place.
                let headLatLng = this._map.containerPointToLatLng(headPoint);
                this._latlngs[this._snakingRings].push(headLatLng);

                this.fire('snakeIn', headLatLng);
                return this;
            },

            _snakeTailForward: function (timeDiff) {
                let forward = timeDiff * this.options.snakingSpeed / 1000;	// In pixels

                // Calculate distance from current vertex to next vertex
                let currPoint = this._map.latLngToContainerPoint(
                    this._snakeLatLngs[this._snakingTailRings][this._snakingTailVertices]);
                let nextPoint = this._map.latLngToContainerPoint(
                    this._snakeLatLngs[this._snakingTailRings][this._snakingTailVertices + 1]);

                let distance = currPoint.distanceTo(nextPoint);

                //console.log('Distance tail to next point:', distance, '; Now at: ', this._snakingTailDistance, '; Must travel forward:', forward, '; _snakingTime', this._snakingTime, '; _snakingTailVertices', this._snakingTailVertices);
                //console.log('Snake vertices: ', this._latlngs,';this._snakeLatLngs',this._snakeLatLngs);

                while (this._snakingTailDistance + forward > distance) {
                    // Jump to next vertex
                    this._snakingTailVertices++;
                    this._latlngs[this._snakingTailRings].shift();

                    if (this._snakingTailVertices >= this._snakeLatLngs[this._snakingTailRings].length - 1) {
                        if (this._snakingTailRings >= this._snakeLatLngs.length - 1) {
                            return this._snakeOutEnd();
                        } else {
                            this._snakingTailVertices = 0;
                            this._latlngs[this._snakingTailRings] = [];
                            this._snakingTailRings++;
                            this._latlngs[this._snakingTailRings].shift(); // Remove first point of new line
                        }
                    }

                    this._snakingTailDistance -= distance;
                    currPoint = this._map.latLngToContainerPoint(
                        this._snakeLatLngs[this._snakingTailRings][this._snakingTailVertices]);
                    nextPoint = this._map.latLngToContainerPoint(
                        this._snakeLatLngs[this._snakingTailRings][this._snakingTailVertices + 1]);
                    distance = currPoint.distanceTo(nextPoint);
                }

                this._snakingTailDistance += forward;

                let percent = this._snakingTailDistance / distance;

                let tailPoint = nextPoint.multiplyBy(percent).add(
                    currPoint.multiplyBy(1 - percent)
                );

                // Put a new tail in place.
                let tailLatLng = this._map.containerPointToLatLng(tailPoint);
                this._latlngs[this._snakingTailRings].unshift(tailLatLng);

                this.fire('snakeOut', tailLatLng);
                return this;
            },


            _snakeInEnd: function () {

                this._snakingIn = false;
                if (!this._snakingOut) {
                    this.setLatLngs(this._snakeLatLngs);
                }
                let lastPath = this._snakeLatLngs[this._snakeLatLngs.length - 1];
                this.fire('snakeInEnd', lastPath[lastPath.length - 1]);

                return this;
            },

            _snakeOutEnd: function () {

                this._snakingOut = false;
                let lastPath = this._snakeLatLngs[this._snakeLatLngs.length - 1];
                this.fire('snakeOutEnd', lastPath[lastPath.length - 1]);

                return this;
            },

            snakeReset: function () {

                this._snakingIn = this._snakingOut = false;
                if (this._snakeLatLngs) {
                    this.setLatLngs(this._snakeLatLngs);
                }

                return this;
            }

        });


        L.Polyline.mergeOptions({
            snakingSpeed: 110,			// In pixels/sec
            className: ' flightRoutes',
            weight: 1.5
        });


        L.LayerGroup.include({

            _snakingLayers: [],
            _snakingLayersDone: 0,
            _snakingTailLayersDone: 0,
            _snakingIn: false,
            _snakingOut: false,

            // used to cancel timeouts for snakeReset()
            _snakeTimeoutsId: [],

            snakeIn: function () {

                if (!('performance' in window) ||
                    !('now' in window.performance) ||
                    !this._map ||
                    this._snakingIn || this._snakingOut) {
                    return;
                }

                this._snakingIn = true;
                this._snakingLayersDone = 0;
                if (this._snakingLayers.length === 0) {
                    this._initSnakingLayers();
                }
                if (this.options.snakeRemoveLayers) {
                    this.clearLayers();
                } else {
                    for (let currentLayer in this._snakingLayers) {
                        if (this._snakingLayers[currentLayer] instanceof L.Polyline) { // remove only paths
                            this.removeLayer(this._snakingLayers[currentLayer]);
                        }
                    }
                }

                this.fire('snakeGroupInStart');
                return this._snakeHeadNext();
            },

            snakeOut: function () {

                if (!('performance' in window) ||
                    !('now' in window.performance) ||
                    !this._map ||
                    this._snakingOut) {
                    return;
                }


                this._snakingOut = true;
                this._snakingTailLayersDone = 0;

                this.fire('snakeGroupOutStart');
                return this._snakeTailNext();
            },

            _initSnakingLayers: function () {
                // Copy layers ref in _snakingLayers
                let keys = Object.keys(this._layers);
                for (let i in keys) {
                    let key = keys[i];
                    this._snakingLayers.push(this._layers[key]);
                }
                return this;
            },

            _snakeHeadNext: function () {

                if (!this._snakingIn) {
                    return this;
                }

                if (this._snakingLayersDone >= this._snakingLayers.length) {
                    this.fire('snakeGroupInEnd');
                    this._snakingIn = false;
                    return;
                }

                let currentLayer = this._snakingLayers[this._snakingLayersDone];

                this._snakingLayersDone++;

                if (!this.getLayer(currentLayer)) { // avoid layer duplications
                    this.addLayer(currentLayer);
                }

                if ('snakeIn' in currentLayer) {
                    currentLayer.once('snakeInEnd', function () {
                        this._snakeTimeoutsId.push(setTimeout(this._snakeHeadNext.bind(this), this.options.snakingPause));
                    }, this);
                    currentLayer.snakeIn();
                } else {
                    this._snakeTimeoutsId.push(setTimeout(this._snakeHeadNext.bind(this), this.options.snakingPause));
                }

                this.fire('snakeGroupInNext');
                return this;
            },

            _snakeTailNext: function () {

                if (!this._snakingOut) {
                    return this;
                }

                if (this.options.snakeRemoveLayers) {
                    this.removeLayer(this._snakingLayers[this._snakingTailLayersDone - 1]);
                }

                if (this._snakingTailLayersDone >= this._snakingLayers.length) {
                    this.fire('snakeGroupOutEnd');
                    this._snakingOut = false;
                    return;
                }
                let currentLayer = this._snakingLayers[this._snakingTailLayersDone];

                this._snakingTailLayersDone++;


                if ('snakeOut' in currentLayer) {
                    currentLayer.once('snakeOutEnd', function () {
                        this._snakeTimeoutsId.push(setTimeout(this._snakeTailNext.bind(this), this.options.snakingPause));
                    }, this);
                    currentLayer.snakeOut();
                } else {
                    this._snakeTimeoutsId.push(setTimeout(this._snakeTailNext.bind(this), this.options.snakingPause));
                }

                this.fire('snakeGroupOutNext');
                return this;
            },

            snakeReset: function () {

                this._snakingIn = false;
                this._snakingOut = false;
                if (this._snakingLayers.length === 0) {
                    this._initSnakingLayers();
                }

                for (let id in this._snakeTimeoutsId) {
                    clearTimeout(id);
                }
                this._snakeTimeoutsId = [];

                for (let currentLayer in this._snakingLayers) {
                    if (this._snakingLayers[currentLayer] instanceof L.Polyline) {
                        this._snakingLayers[currentLayer].snakeReset();
                    }
                    // Maybe we need to keep layer order
                    if (!this.getLayer(this._snakingLayers[currentLayer])) {
                        this.addLayer(this._snakingLayers[currentLayer]);
                    }
                }
                return this;
            }

        });


        L.LayerGroup.mergeOptions({
            snakingPause: 200,
            snakeRemoveLayers: true // should layers (other than polyline) disappear
        });
    }

    useEffect(() => {
        FlightCluster()


    }, [flightClusterData])

    const getPathColor = () => {
        let arr_color = [
            '#ef5350',
            '#ffca28',
            '#67904A',
            '#5c6bc0',
            '#7e57c2'
        ];
        return arr_color[Math.floor((Math.random()*arr_color.length))];
    }


    const FlightCluster = () => {
        setPathColor(getPathColor());

        SnakeFlight();
        let arr_coordinates = flightClusterData;
        let post_1 = arr_coordinates[0];
        let post_2 = arr_coordinates[1];
        getLinned(map, post_1, post_2, indexClass)
        return null;
    }


    const getLinned = (map, post_1, post_2,) => {
        let line_data = curvedLine(map, post_1, post_2, indexClass);
        line_data.shift();
        line_data.unshift(post_1);
        line_data.pop();
        line_data.push(post_2);
        const len = line_data.length;

        let path = L.polyline(line_data, {snakingTimeSpeed: 1, color: pathColor});
        map.addLayer(path);
        // path.bindPopup("Hello world");
        path.snakeIn();

        function snakeReset() {
            path.snakeReset();
        }

        path.on('snakeInEnd', function (ev) {
            setTimeout(function () {
                path.snakeIn()
            }, 2000);


        });
        return null;
    }


    const extractTrace = () => {
        var i = 0;
        var arr = [];
        for (i; i <= 100; i++) {
            var n = i;
            if (i < 10) {
                n = '0.0' + i;
            } else {
                n = '0.' + i;
                if (i == 100) {
                    n = 1;
                }
            }
            var row = parseFloat(n);
            arr.push(row);
        }
        return arr;
    }


    const curvedLine = (map, latlang1, latlang2, indexClass) => {


        let latlngs = [];

        let latlng1 = latlang1,
            latlng2 = latlang2;

        let offsetX = latlng2[1] - latlng1[1],
            offsetY = latlng2[0] - latlng1[0];

        let r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
            theta = Math.atan2(offsetY, offsetX);

        let thetaOffset = (3.14 / 10);

        if (offsetX <= 0) {
            thetaOffset *= -1;
        }

        let r2 = (r / 2) / (Math.cos(thetaOffset)),
            theta2 = theta + thetaOffset;

        let midpointX = (r2 * Math.cos(theta2)) + latlng1[1],
            midpointY = (r2 * Math.sin(theta2)) + latlng1[0];

        let midpointLatLng = [midpointY, midpointX];

        latlngs.push(latlng1, midpointLatLng, latlng2);

        let pathOptions = {
            color: 'transparent',
            weight: 1,
            className: indexClass
        }

        let curvedPath = L.curve(
            [
                'M', latlng1,
                'Q', midpointLatLng,
                latlng2,

            ], pathOptions);
        curvedPath.addTo(map);

        let curved_path = curvedPath.trace(extractTrace());
        let curved_array = Object.values(curved_path);
        let i = 0;
        let arr = [];
        for (i; i < curved_array.length; i++) {

            let row = [curved_array[i].lat, curved_array[i].lng]

            arr.push(row);
        }
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll("." + indexClass));

        return arr;
    }
    return null
}





