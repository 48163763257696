import Map from "../reusable/Map";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fullLogo from "../img/logo-full.jpg";
import { Alert, Form, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "@material-ui/core/Button";
import Palette from "../util/Palette";
import User from "../models/User";
import { useHistory } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import swal from 'sweetalert';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default function RegisterPage(props) {
    const history = useHistory()

    const userModel = new User();

    const [registerData, setRegisterData] = useState({ registerAs: 'sender' });

    const [address, setAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [checkendFreeCargo, setCheckendFreeCargo] = useState(false);
    const [provinces, setProvinces] = useState([])
    const [openFreeCargoDialog, setOpenFreeCargoDialog] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const handleResize = () => {
        console.log('window.innerWidth', window.innerWidth)

        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        getProvinces();
        if (localStorage.getItem("token")) {
            history.push("/simpul")
        }
    }, [])

    const getProvinces = async () => {
        const result = await userModel.getAllProvince();
        setProvinces(result)
    }

    const handleFreeCargoDialog = (e) => {
        e.preventDefault();
        setOpenFreeCargoDialog(true)
    }
    const handleFreeCargoDialogClose = (e) => {
        e.preventDefault();
        setOpenFreeCargoDialog(false)
    }

    const handleClickUnderstandCargo = (e) => {
        setCheckendFreeCargo(true)
        e.preventDefault();
        setOpenFreeCargoDialog(false)
    }

    const handleRegister = async () => {

        if (!registerData.email || !registerData.password || !registerData.full_name || !registerData.phone_number) {
            swal("Registrasi Gagal!", 'Harap mengisi form dengan lengkap.', "error");
        } else if (registerData.password !== registerData.confirmPassword) {
            swal("Registrasi Gagal!", 'Konfirmasi kata sandi salah.', "error");
        } else if (!checkendFreeCargo) {
            swal("Registrasi Gagal!", 'Harap menyetujui Syarat Pengiriman Paket Free Cargo Literacy.', "error");
        } else {
            setLoading(true)
            if (registerData.province == 'Kepulauan Bangka Belitung') {
                registerData.province = 'Bangka Belitung'
            }
            let province_id;
            provinces.forEach(value => {
                if (value.name == registerData.province) {
                    registerData.province = value.id;
                    province_id = value.id
                }
            });


            if (province_id == undefined) {
                swal("Registrasi Gagal!", 'Lokasi tidak valid, hanya dapat di gunakan di wilayah indonesia. Silahkan menggunakan marker agar lokasi ditemukan', "error");

                setLoading(false)
                return
            }

            try {
                const result = await userModel.register({
                    ...registerData,
                    address: JSON.stringify({
                        detail: address,
                        latitude,
                        longitude
                    })
                })

                setLoading(true)

                if (result.id) {
                    swal("Registrasi Berhasil!", "Silakan masuk dengan akun yang sudah dibuat.", "success").then(value => {
                        history.push('/masuk')
                    });
                }

                console.log(result)
            } catch (e) {
                setLoading(false)
                if (e.msg) {
                    swal("Registrasi Gagal!", e.msg, "error");
                } else {
                    swal("Registrasi Gagal!", "Kesalahan terjadi.", "error");
                }
                console.log(e)
            }
        }

        // {
        //     address: {
        //         detail,
        //             latitude,
        //             longitude
        //     }
        // }
        console.log(address)
        console.log(latitude)
        console.log(longitude)
        console.log(registerData)
    }

    return (

        <>

            <Container style={{
                minHeight: "100vh",
                paddingTop: 30,
                paddingBottom: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col md={5}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 20,
                            paddingBottom: 50
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: 20,
                                width: "90%"
                            }}>
                                <img
                                    style={{
                                        width: "100%",
                                        marginTop: 30,
                                        marginBottom: 30,
                                    }}
                                    src={fullLogo} />

                                <Form style={{
                                    width: "100%",
                                }}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Alamat Surel</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleRegister()
                                                }
                                            }}
                                            value={registerData.email} onChange={evt => setRegisterData({
                                                ...registerData,
                                                email: evt.target.value
                                            })} type="email" placeholder="Alamat Surel" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Kata Sandi</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleRegister()
                                                }
                                            }}
                                            value={registerData.password} onChange={evt => setRegisterData({
                                                ...registerData,
                                                password: evt.target.value
                                            })} type="password" placeholder="Kata Sandi" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Konfirmasi Kata Sandi</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleRegister()
                                                }
                                            }}
                                            value={registerData.confirmPassword}
                                            onChange={evt => setRegisterData({
                                                ...registerData,
                                                confirmPassword: evt.target.value
                                            })} type="password" placeholder="Kata Sandi" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleRegister()
                                                }
                                            }}
                                            value={registerData.full_name} onChange={evt => setRegisterData({
                                                ...registerData,
                                                full_name: evt.target.value
                                            })} placeholder="Nama" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Nomor Telepon</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleRegister()
                                                }
                                            }}
                                            value={registerData.phone_number}
                                            type={'tel'}
                                            onChange={evt => setRegisterData({
                                            ...registerData,
                                            phone_number: evt.target.value
                                        })} placeholder="Nomor Telepon" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail" style={{ marginBottom: 55 }}>
                                        <Form.Label>Alamat</Form.Label>
                                        <Map
                                            address={address}
                                            onDataChanged={(data) => {
                                                setLatitude(data.mapPosition.lat)
                                                setLongitude(data.mapPosition.lng)
                                                setAddress(data.address)
                                                setRegisterData({
                                                    ...registerData,
                                                    province: data.state
                                                })
                                            }}
                                            google={props.google}
                                            center={{
                                                lat: latitude ? latitude : -6.2297419,
                                                lng: longitude ? longitude : 106.759478
                                            }}
                                            height='250px'
                                            zoom={15}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Alamat</Form.Label>
                                        <Form.Control
                                            style={{
                                                height: 100
                                            }}
                                            as={'textarea'}
                                            value={address} disabled={true} type="email" placeholder="Alamat" />
                                    </Form.Group>
                                    <Alert variant='warning'>
                                        <FaInfoCircle></FaInfoCircle>   Apabila alamat tidak ditemukan, Anda dapat menggunakan marker yang ada di peta
                                    </Alert>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Daftar Sebagai</Form.Label>
                                        <Form.Control as="select"
                                            onChange={(e) => setRegisterData({
                                                ...registerData,
                                                registerAs: e.target.value
                                            })}
                                            value={registerData.registerAs}>
                                            <option value={'sender'}>Pengirim</option>
                                            <option value={'receiver'}>Penerima</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                                <div style={{
                                    display: 'flex',
                                    flex: 2,
                                    flexDirection: 'row',
                                    alignItems: 'center',

                                }}>
                                    <div style={{
                                        width: 'auto'
                                    }}>

                                        <Form.Group onChange={(e) => setCheckendFreeCargo(!checkendFreeCargo)} className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check checked={checkendFreeCargo} type="checkbox" />
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        width: 'auto',
                                        fontSize: '0.7em',
                                        textAlgin: 'center'
                                    }}>
                                        Dengan mengklik Daftar, Anda menyetujui <a href="#" onClick={handleFreeCargoDialog}>Syarat Pengiriman Paket Free Cargo Literacy</a>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        handleRegister()
                                    }}
                                    style={{
                                        width: "100%", marginTop: 15
                                    }}
                                    variant={"contained"} disabled={isLoading} color="primary" type="submit">
                                    {isLoading ?
                                        <Spinner animation="border" size="sm"
                                            style={{ marginTop: 4, marginBottom: 4 }} /> :
                                        "Daftar"}
                                </Button>
                            </div>


                        </Card>

                    </Col>
                </Row>
            </Container>
            <Dialog open={openFreeCargoDialog} aria-labelledby="form-dialog-title">
                <MuiDialogTitle onClick={handleFreeCargoDialogClose}>
                    <IconButton onClick={handleFreeCargoDialogClose} style={{
                        position: 'absolute',
                        right: 0
                    }} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent>
                    <Row>
                        <Col md={12}>
                            <DialogTitle id="alert-dialog-title">
                                Syarat Pengiriman Paket Free Cargo Literacy
                            </DialogTitle>


                            <DialogContentText id="alert-dialog-description"
                                style={{ marginLeft: 15, padding: '16px 24px', paddingTop: 0 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        1.
                                    </div>
                                    Paket hanya berisi buku dan alat-alat tulis (diajurkan terkemas dalam
                                    kotak plastik tembus pandang yang mudah jadi rak buku).
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        2.
                                    </div>
                                    Data pengirim dan/atau penerima telah terdaftar di database yang
                                    dipegang oleh PT Pos Indonesia.
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        3.
                                    </div>
                                    Setiap donatur hanya dapat mengirim 1 paket donasi berat maksimal 10
                                    kg.
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        4.
                                    </div>
                                    Donasi dikirim melalui kantor pos (termasuk agen pos yang memenuhi
                                    syarat) di seluruh Indonesia.
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        5.
                                    </div>
                                    Mencantumkan kata pengenal #INDONESIAMAJU
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                    <div style={{ marginRight: 10 }}>
                                        6.
                                    </div>
                                    Data pengirim / penerima berupa alamat dan nomor telefon akan ditampilkan pada visualisasi Free Cargo Literacy
                                </div>
                            </DialogContentText>
                            <Button
                                onClick={handleClickUnderstandCargo}
                                style={{
                                    marginTop: 15, float: 'right'
                                }}
                                variant={"contained"} disabled={isLoading} color="primary">
                                {isLoading ?
                                    <Spinner animation="border" size="sm"
                                        style={{ marginTop: 4, marginBottom: 4 }} /> :
                                    "Mengerti"}
                            </Button>
                        </Col>


                    </Row>
                </DialogContent>
            </Dialog>

        </>
    )
}