import ApiRequest from "../util/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Donation {
    create = async (body) => {
        return await ApiRequest.set("/v1/donation", "POST", body);
    }

    get = async () => {
        return await ApiRequest.set("/v1/donation", "GET");
    }

    getAccepted = async () => {
        return await ApiRequest.set("/v1/accepted-donation", "GET");
    }
    getDonationData = async (years) => {
        return await ApiRequest.set(`/v1/all-donation-years/${years}`, "GET");
    }

    getPending = async () => {
        return await ApiRequest.set("/v1/pending-donation/count", "GET");
    }

    getRankingByYear= async (years) => {
        return await ApiRequest.set(`/v1/ranking-donation-by-year/${years}`, "GET");
    }

    getRankingAll= async () => {
        return await ApiRequest.set(`/v1/ranking-donation`, "GET");
    }
}