import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import fullLogo from "../img/logo-full.jpg";
import Autosuggest from "react-autosuggest";
import Button from "@material-ui/core/Button";
import {IoMdExit} from "react-icons/io";
import {HiOutlineLogin, HiOutlineSearch} from "react-icons/hi";
import {MdClose} from "react-icons/md";
import {useHistory} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";

export default function Header(props) {
    const [isSearchMode, setSearchMode] = useState(false);

    const history = useHistory()

    const {user, receivers, noOfPendingDonation, setSelectedReceiverId, setPosition, setDetail, isSidebarShown, setSidebarShown, hideSearchBar} = props;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');
    const [isInputHovered, setInputHovered] = useState(false)
    const onChange = (event, {newValue}) => {
        setValue(newValue)
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        // return []
        let filteredReceivers = inputLength === 0 ? [] : receivers.filter(receiver => {
            return receiver.full_name.toLowerCase().includes(inputValue) || receiver.address.detail.toLowerCase().includes(inputValue)
        });

        return filteredReceivers.filter((receiver, idx) => {
            return idx < 5
        })
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = suggestion => suggestion.full_name;

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div>
                <HiOutlineSearch color={'grey'}/>
            </div>
            <div style={{marginLeft: 20, flex: 1}}>
                {suggestion.full_name}

                <div style={{color: 'grey', fontSize: '.9em'}}>
                    {suggestion.address.detail}
                </div>
            </div>
        </div>
    );

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({value}) => {
        setSuggestions(getSuggestions(value))
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const handleResize = () => {
        console.log('window.innerWidth', window.innerWidth)

        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const renderSidebar = () => {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 9999999999,
                backgroundColor: 'white',
                padding: 15,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <div style={{
                    position: 'absolute',
                    right: 15,
                    top: 25,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <IconButton onClick={() => {
                        setSidebarShown(false)
                    }}>
                        <MdClose color={'grey'}
                                 style={{cursor: 'pointer'}}
                                 size={30}
                        />
                    </IconButton>
                </div>

                <img
                    style={{
                        width: "100%",
                        maxWidth: 150,
                        marginTop: 20,
                    }}
                    src={fullLogo}/>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {setDetail ?
                        <Autosuggest
                            onSuggestionSelected={(event, {
                                suggestion,
                                suggestionValue,
                                suggestionIndex,
                                sectionIndex,
                                method
                            }) => {
                                setSidebarShown(false)
                                setSelectedReceiverId(suggestion.id)
                                setPosition([suggestion.address.latitude, suggestion.address.longitude])

                                setDetail({
                                    image: JSON.parse(suggestion.photo_urls),
                                    name: suggestion.full_name,
                                    address: suggestion.address.detail,
                                    noOfBooks: suggestion.received_books,
                                    phoneNo: suggestion.phone_number,
                                })
                            }}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            renderInputComponent={(inputProps) => {
                                return (
                                    <div style={{position: 'relative'}}>
                                        <div style={{
                                            position: 'absolute',
                                            left: 20,
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <HiOutlineSearch color={'grey'}/>
                                        </div>
                                        <input {...inputProps}
                                               onMouseOver={() => {
                                                   setInputHovered(true)
                                               }}
                                               onMouseLeave={() => {
                                                   setInputHovered(false)
                                               }}
                                               style={{
                                                   paddingLeft: 55,
                                                   borderRadius: 20,
                                                   borderBottomLeftRadius: suggestions.length > 0 ? 0 : 20,
                                                   borderBottomRightRadius: suggestions.length > 0 ? 0 : 20,
                                                   height: 42,
                                                   paddingRight: 55,
                                                   textAlign: 'center',
                                                   boxShadow: `0 2px 5px 1px rgba(64,60,67,${isInputHovered || value.length > 0 ? '.32' : '.16'})`
                                               }}/>

                                        {value.length > 0 &&
                                        <div style={{
                                            position: 'absolute',
                                            right: 20,
                                            top: 13,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <MdClose color={'grey'}
                                                     style={{cursor: 'pointer'}}
                                                     onClick={() => {
                                                         setValue("")
                                                     }}/>
                                        </div>
                                        }
                                    </div>
                                )
                            }}
                            inputProps={{
                                placeholder: 'Cari Simpul Pustaka',
                                value,
                                onChange
                            }}
                        /> : null
                    }

                    <a href={'#'}
                       onClick={(e) => {
                           e.preventDefault();

                           history.push("/fcl");
                       }}
                       style={{
                           fontWeight: '600',
                           fontSize: '1.4em',
                           marginBottom: 30,
                           color: '#68934d',
                           marginTop: 30
                       }}>Free Cargo Literacy</a>

                    <a href={'https://pustakabergerak.id/'}
                       target={'_blank'}
                       onClick={() => {
                           setSidebarShown(false)
                       }}
                       style={{
                           fontWeight: '600',
                           fontSize: '1.4em',
                           marginBottom: 30,
                           color: '#68934d'
                       }}>pustakabergerak.id</a>


                    {localStorage.getItem("token") ?
                        <>
                            <a href={'#'}
                               onClick={(e) => {
                                   e.preventDefault();

                                   history.push("/status-pengiriman");
                               }}
                               style={{
                                   fontWeight: '600',
                                   fontSize: '1.4em',
                                   marginBottom: 30,
                                   color: '#68934d'
                               }}>Status Pengiriman</a>
                            <a href={'#'}
                               onClick={(e) => {
                                   e.preventDefault();

                                   history.push("/profil");
                               }}
                               style={{fontWeight: '600', fontSize: '1.4em', color: '#68934d'}}>Profil {user.loginAs === 'sender' ? 'Pengirim' : 'Penerima'}</a>
                        </> : null
                    }
                </div>

                <div style={{width: '100%', marginTop: 60, marginBottom: 10}}>
                    <Button
                        onClick={() => {
                            if (localStorage.getItem("token")) {
                                localStorage.removeItem("token")
                                window.location.reload();
                            } else {
                                history.push('/masuk')
                            }

                        }}
                        endIcon={localStorage.getItem("token") ? <IoMdExit/> : <HiOutlineLogin/>}
                        variant={"contained"} color="primary" style={{marginRight: 10, width: '100%'}}>
                        {localStorage.getItem("token") ? "Keluar" : "Masuk"}
                    </Button>
                </div>
            </div>

        )
    }

    // return renderHeaderMobile()

    if(isSidebarShown) return renderSidebar()

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '1px 0px 5px 0px rgba(0,0,0,0.3)',
                paddingLeft: 15,
                zIndex: 999999999
            }}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                    {windowWidth > 900 ?
                        <>
                            {setDetail ?
                                <Autosuggest
                                    onSuggestionSelected={(event, {
                                        suggestion,
                                        suggestionValue,
                                        suggestionIndex,
                                        sectionIndex,
                                        method
                                    }) => {
                                        setSelectedReceiverId(suggestion.id)
                                        setPosition([suggestion.address.latitude, suggestion.address.longitude])

                                        setDetail({
                                            image: JSON.parse(suggestion.photo_urls),
                                            name: suggestion.full_name,
                                            address: suggestion.address.detail,
                                            noOfBooks: suggestion.received_books,
                                            phoneNo: suggestion.phone_number,
                                        })
                                    }}
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    renderInputComponent={(inputProps) => {
                                        return (
                                            <div style={{position: 'relative', zIndex: hideSearchBar ? 0 : 9999999999}}
                                                 onClick={() => {
                                                     if (!isSearchMode) {
                                                         setSearchMode(true)
                                                     }
                                                 }}
                                            >
                                                <div style={{
                                                    position: 'absolute',
                                                    left: 20,
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <HiOutlineSearch
                                                        color={'grey'}/>
                                                </div>

                                                {isSearchMode ?
                                                    <div style={{
                                                        position: 'absolute',
                                                        right: 15,
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        <MdClose color={'grey'}
                                                                 onClick={() => {
                                                                     setValue("")
                                                                     setSearchMode(false)
                                                                 }}
                                                        />
                                                    </div> : null
                                                }

                                                <input {...inputProps}
                                                       onMouseOver={() => {
                                                           setInputHovered(true)
                                                       }}
                                                       onMouseLeave={() => {
                                                           setInputHovered(false)
                                                       }}
                                                       style={{
                                                           pointerEvents: isSearchMode ? 'auto' : 'none',
                                                           paddingLeft: 55,
                                                           borderRadius: 20,
                                                           borderBottomLeftRadius: suggestions.length > 0 ? 0 : 20,
                                                           borderBottomRightRadius: suggestions.length > 0 ? 0 : 20,
                                                           height: 42,
                                                           paddingRight: isSearchMode ? 40 : 2,
                                                           width: isSearchMode ? 240 : 0,
                                                           transition: 'width .3s',
                                                           boxShadow: `0 2px 5px 1px rgba(64,60,67,${isInputHovered || value.length > 0 ? '.32' : '.16'})`
                                                       }}/>


                                                {/*{value.length > 0 &&*/}
                                                {/*<div style={{*/}
                                                {/*    position: 'absolute',*/}
                                                {/*    right: 20,*/}
                                                {/*    top: 13,*/}
                                                {/*    display: 'flex',*/}
                                                {/*    alignItems: 'center'*/}
                                                {/*}}>*/}
                                                {/*    <MdClose color={'grey'}*/}
                                                {/*             style={{cursor: 'pointer'}}*/}
                                                {/*             onClick={() => {*/}
                                                {/*                 setValue("")*/}
                                                {/*             }}/>*/}
                                                {/*</div>*/}
                                                {/*}*/}
                                            </div>
                                        )
                                    }}
                                    inputProps={{
                                        placeholder: 'Cari Simpul Pustaka',
                                        value,
                                        onChange
                                    }}
                                /> : null
                            }

                            <div style={{
                                opacity: isSearchMode ? 0 : 1,
                                transition: 'opacity .3s',
                                pointerEvents: isSearchMode ? 'none' : 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: 15
                            }}>
                                <a href={'#'}
                                   onClick={(e) => {
                                       e.preventDefault();

                                       history.push("/fcl");
                                   }}
                                   style={{
                                       color: '#67904A',
                                       fontWeight: '600'
                                   }}>
                                    Free Cargo Literacy
                                </a>

                                <a href={'https://pustakabergerak.id/'}
                                   target={'_blank'}
                                   style={{
                                       color: '#67904A',
                                       fontWeight: '600',
                                       marginLeft: 20
                                   }}>
                                    pustakabergerak.id
                                </a>
                            </div>
                        </> :
                        <IconButton onClick={() => {
                            setSidebarShown(true)
                        }}>
                            <GiHamburgerMenu color={'#67904A'} />
                        </IconButton>
                    }
                </div>
                <img
                    onClick={() => {
                        history.push('/')
                    }}
                    style={{
                        width: "100%",
                        maxWidth: 150,
                        marginTop: 20,
                        marginBottom: 20,
                        cursor: 'pointer'
                    }}
                    src={fullLogo}/>

                <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {
                        windowWidth > 900 ?
                            <>
                                {localStorage.getItem("token") &&
                                <div style={{
                                    fontWeight: '600',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginRight: 20
                                }}>
                                    <a href={'#'}
                                       onClick={(e) => {
                                           e.preventDefault();

                                           history.push("/status-pengiriman");
                                       }}
                                       style={{
                                           color: '#67904A'
                                       }}>
                                        Status Pengiriman
                                    </a>

                                    {noOfPendingDonation > 0 ?
                                        <div style={{
                                            marginTop: -10,
                                            width: 20,
                                            height: 20,
                                            backgroundColor: '#67904A',
                                            borderRadius: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: 'white',
                                            fontSize: 13
                                        }}>
                                            {noOfPendingDonation}
                                        </div> : null
                                    }
                                </div>
                                }

                                {localStorage.getItem("token") &&
                                <a href={'#'}
                                   onClick={(e) => {
                                       e.preventDefault();

                                       history.push("/profil");
                                   }}
                                   style={{
                                       color: '#67904A',
                                       fontWeight: '600',
                                       marginRight: 20
                                   }}>
                                    Profil {user.loginAs === 'sender' ? 'Pengirim' : user.loginAs === 'receiver' ? 'Penerima' : null}
                                </a>
                                }

                                <Button
                                    onClick={() => {
                                        if (localStorage.getItem("token")) {
                                            localStorage.removeItem("token")
                                            window.location.reload();
                                        } else {
                                            history.push('/masuk')
                                        }

                                    }}
                                    endIcon={localStorage.getItem("token") ? <IoMdExit/> : <HiOutlineLogin/>}
                                    variant={"contained"} color="primary" style={{marginRight: 10}}>
                                    {localStorage.getItem("token") ? "Keluar" : "Masuk"}
                                </Button>
                            </> : null
                    }
                </div>
            </div>
        </>
    )

}
