import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Scrollbars } from "react-custom-scrollbars";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Card, CardContent, Divider, Collapse, CardHeader, Avatar, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));
export default function LegendInfo(props) {
    const { hide, windowWidth, shownLegendDonation, setShownLegendDonation, allYears, year } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false)


    return (
        <>
            {
                windowWidth > 1280 ? <IconButton
                    className={shownLegendDonation ? "btnLegendActive" : ''}
                    onClick={(x) => {
                        setShownLegendDonation(!shownLegendDonation)
                    }}
                    variant={"contained"} color="default" style={{
                        right: '2%',
                        position: 'absolute',
                        top: '23%',
                        zIndex: hide ? 0 : 10000,
                        height: '50px',
                        width: '50px',
                        borderRadius: '100%',
                        background: '#ffffffeb',
                        transition: 'background-color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        transitionProperty: 'background-color, box-shadow, border',
                        transitionDuration: '250ms, 250ms, 250ms',
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDelay: '0ms, 0ms, 0ms',
                        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                    }}>
                    <InfoOutlined style={{
                        'color': 'rgb(138 138 137)',
                        'width': '1.2em',
                        'height': '1.2em'
                    }
                    } ></InfoOutlined>
                </IconButton> : <IconButton
                    className={shownLegendDonation ? "btnLegendActive" : ''}
                    onClick={(x) => {
                        setShownLegendDonation(!shownLegendDonation)
                    }}
                    variant={"contained"} color="default" style={{
                        left: '2%',
                        position: 'absolute',
                        top: '25%',
                        zIndex: hide ? 0 : 10000,
                        height: '50px',
                        width: '50px',
                        borderRadius: '100%',
                        background: '#ffffffeb',
                        transition: 'background-color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        transitionProperty: 'background-color, box-shadow, border',
                        transitionDuration: '250ms, 250ms, 250ms',
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDelay: '0ms, 0ms, 0ms',
                        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                    }}>
                    <InfoOutlined style={{
                        'color': 'rgb(138 138 137)',
                        'width': '1em',
                        'height': '1em'
                    }
                    } ></InfoOutlined>
                </IconButton>
            }

            {
                shownLegendDonation ? windowWidth > 1280 ? <Card boxShadow={2} class="cardInfoLegend" style={{
                    position: 'absolute',
                    top: '22%',
                    zIndex: hide ? 0 : 100000,
                    right: '74px',
                    padding: '10px 10px 0px 10px',
                    margin: '10px 10px 20px 20px',
                    maxHeight: '300px',

                    width: '35%',
                    background: '#ffffffe3',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <p style={{

                        }}>Legenda</p>
                    </div>
                    <Divider variant="middle" />
                    <Scrollbars style={{ width: '100%', height: '180px' }}>
                        <CardContent>
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 25, fontSize: '.9em', alignItems: 'center'}}>
                                <img style={{ width: '32px', height: '32px', objectFit: 'contain', marginRight: 15 }} src="/images/buku_terbuka_small.png"></img>

                                Simpul Pustaka / Penerima Buku
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', fontSize: '.9em', alignItems: 'center'}}>
                                <img style={{ width: '32px', height: '32px', objectFit: 'contain', marginRight: 15 }} src="/images/buku_tertutup_small.png"></img>

                                Donatur / Pengirim Buku
                            </div>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <div style={{
                                    width: 'auto',
                                    'fontSize': '0.8em',
                                    marginBottom: '10px'
                                }}>

                                    1. Untuk mengetahui Simpul Pustaka, silahkan klik icon <img style={{ width: '25px', height: '20px' }} src="/images/buku_terbuka_small.png"></img>
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>


                                <div style={{ width: 'auto', 'fontSize': '0.8em',
                                    marginBottom: '10px' }}>
                                    2. Untuk mengetahui penerima, silahkan klik icon <img style={{ width: '25px', height: '20px' }} src="/images/buku_tertutup_small.png"></img>
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>


                                <div style={{ width: 'auto',  'fontSize': '0.8em',
                                    marginBottom: '10px' }}>
                                    3. Garis berwarna adalah  lintasan pergerakan buku
                                </div>
                            </div>
                        </Collapse>
                        <CardActions disableSpacing>
                            <IconButton
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}

                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>
                    </Scrollbars>


                </Card> : <Card boxShadow={2} class="cardInfoLegendMini" style={{
                    position: 'absolute',
                    top: '24%',
                    zIndex: hide ? 0 : 100000,
                    left: '50px',
                    padding: '10px 10px 0px 10px',
                    margin: '10px 10px 20px 20px',
                    maxHeight: '300px',

                    width: '55%',
                    background: '#ffffffe3',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <p style={{

                        }}>Legenda</p>
                    </div>
                    <Divider variant="middle" />
                    <Scrollbars style={{ width: '100%', height: '180px' }}>
                        <CardContent>
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 25, fontSize: '.9em', alignItems: 'center'}}>
                                <img style={{ width: '32px', height: '32px', objectFit: 'contain', marginRight: 15 }} src="/images/buku_terbuka_small.png"></img>

                                Simpul Pustaka / Penerima Buku
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', fontSize: '.9em', alignItems: 'center'}}>
                                <img style={{ width: '32px', height: '32px', objectFit: 'contain', marginRight: 15 }} src="/images/buku_tertutup_small.png"></img>

                                Donatur / Pengirim Buku
                            </div>
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <div style={{
                                    width: 'auto',
                                    'fontSize': '0.8em',
                                    marginBottom: '10px'
                                }}>
                                    1. Untuk mengetahui Simpul Pustaka, silahkan klik icon <img style={{ width: '25px', height: '20px' }} src="/images/buku_terbuka_small.png"></img>
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>


                                <div style={{ width: 'auto', 'fontSize': '0.8em',
                                    marginBottom: '10px'}}>
                                    2. Untuk mengetahui penerima, silahkan klik icon <img style={{ width: '25px', height: '20px' }} src="/images/buku_tertutup_small.png"></img>
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>


                                <div style={{ width: 'auto', 'fontSize': '0.8em',
                                    marginBottom: '10px' }}>
                                    3. Garis berwarna adalah  lintasan pergerakan buku
                                </div>
                            </div>
                        </Collapse>
                        <CardActions disableSpacing>
                            <IconButton
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}

                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>
                    </Scrollbars>


                </Card> : ''
            }

        </>




    )
}
