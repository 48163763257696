import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fullLogo from "../img/logo-full.jpg";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {FiChevronRight} from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Form, Spinner} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {AiOutlineClose} from "react-icons/ai";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {ImFilesEmpty} from "react-icons/im";
import Donation from "../models/Donation";
import User from "../models/User";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import {
    Close
} from '@material-ui/icons';
import Delivery from "../models/Delivery";
import {useHistory} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import {IoMdExit} from "react-icons/io";
import {HiOutlineLogin} from "react-icons/hi";
import {MdClose} from "react-icons/md";
import Header from "../reusable/Header";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

export default function MyDonationPage() {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true)

    const donationModel = new Donation();

    const userModel = new User();

    const [user, setUser] = useState({})
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isModalVisible, setModalVisible] = useState(false);
    const [pendingData, setPendingData] = useState([]);
    const [acceptedData, setAcceptedData] = useState([]);
    const [selectedDonation, setSelectedDonation] = useState({})

    const [isTrackingModalVisible, setTrackingModalVisible] = useState(false);
    const [selectedResiNo, setSelectedResiNo] = useState('');
    const [isManifestLoading, setIsManifestLoading] = useState(false);
    const [manifest, setManifest] = useState([]);
    const [noOfPendingDonation, setNoOfPendingDonation] = useState(0);
    const [isSidebarShown, setSidebarShown] = useState(false);

    const getNoOfPendingDonation = async () => {
        try {
            const result = await donationModel.getPending();

            setNoOfPendingDonation(result[0].count_pending_donations)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getNoOfPendingDonation()
        }
    }, [])

    const tokenLogin = async () => {
        try {
            const user = await userModel.tokenLogin();
            console.log('user',user)
            setUser(user)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const getData = async () => {
        try {
            const result = await donationModel.get()

            const acceptedDataTemp = [];
            const pendingDataTemp = [];

            result.forEach(datum => {
                if (datum.status === 'ACCEPTED') {
                    acceptedDataTemp.push(datum)
                } else if (datum.status === 'PENDING') {
                    pendingDataTemp.push(datum)
                }
            })

            setAcceptedData(acceptedDataTemp)
            setPendingData(pendingDataTemp)

            setLoading(false)
            console.log(result)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const NoDataView = () => (
        <Row>
            <Col>
                <div style={{
                    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 8px',
                    padding: 15,
                    borderRadius: 5,
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <ImFilesEmpty size={50} color={'#c7c7c7'}/>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600',
                        marginTop: 10,
                        color: 'grey'
                    }}>
                        Tidak Ada Data
                    </div>
                </div>

            </Col>
        </Row>
    )

    const LoadingView = () => (
        <Row>
            <Col>
                <div style={{
                    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 8px',
                    padding: 15,
                    borderRadius: 5,
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 30,
                    paddingBottom: 30
                }}>
                    <Spinner animation={"border"} variant={"success"}/>
                </div>

            </Col>
        </Row>
    )

    const TrackingModal = () => (
        <Dialog
            open={isTrackingModalVisible}
            maxWidth={"md"}
            fullWidth
        >
            <DialogTitle style={{backgroundColor: '#67904A'}}>
                <div style={{display: "flex"}}>
                    <div style={{flex: 1}}>
                        <Typography variant={"h6"} style={{color: 'white'}}>
                            Tracking {selectedResiNo}
                        </Typography>
                    </div>
                    <div>
                        <Close onClick={() => {
                            setTrackingModalVisible(false)
                        }} style={{cursor: "pointer", color: 'white'}}/>
                    </div>
                </div>
            </DialogTitle>

            <DialogContent style={{margin: "16px 8px"}}>
                <Row>
                    <Col md={12}>
                        {
                            !isManifestLoading ?
                                <Stepper activeStep={0} orientation="vertical">
                                    {
                                        manifest.length >= 1 &&
                                        manifest.map((obj, key) => {
                                            return <Step key={key} active={true}>
                                                <StepLabel StepIconComponent={() => {
                                                    return <div style={{
                                                        height: 24,
                                                        width: 24,
                                                        borderRadius: 12,
                                                        background: '#67904A'
                                                    }}></div>
                                                }}>
                                                    <div style={{
                                                        fontWeight: '600',
                                                        fontSize: '1.1em'
                                                    }}>{obj.label}</div>
                                                </StepLabel>
                                                <StepContent style={{
                                                    fontSize: '.8em',
                                                    color: 'grey',
                                                    marginTop: 4
                                                }}>
                                                    {obj.content}
                                                </StepContent>
                                            </Step>
                                        })
                                    }
                                </Stepper> : <div>Loading...</div>
                        }
                    </Col>
                </Row>
            </DialogContent>
        </Dialog>
    )

    const DonationCard = (props) => {
        const {donation} = props

        return (
            <Row>
                <Col>
                    <div style={{
                        boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 8px',
                        padding: 15,
                        borderRadius: 5,
                        marginTop: 10
                    }}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{color: 'grey', flex: 1}}>
                                Tanggal Pembuatan Donasi: {moment(donation.created_at).format('DD MMM YYYY')}
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();

                                        setSelectedDonation({
                                            ...donation,
                                            photo_urls: JSON.parse(donation.photo_urls)
                                        })
                                        setModalVisible(true)
                                    }}
                                    href={'#'} style={{fontWeight: '600', color: '#67904A'}}>Lihat Detail</a>

                                <FiChevronRight color={'#67904A'} style={{marginTop: 3}}/>
                            </div>
                        </div>
                        <div style={{color: 'grey', display: 'flex', flexDirection: "row"}}>
                            {user.loginAs === 'sender' ? 'Penerima' : 'Pengirim'}: <div style={{
                            fontWeight: '700',
                            color: '#67904A',
                            marginLeft: 6
                        }}>{user.loginAs === 'sender' ? donation.receiver_full_name : donation.sender_full_name}</div>
                        </div>
                        <div style={{color: 'grey'}}>
                            Alamat: {JSON.parse(user.loginAs === 'sender' ? donation.receiver_address : donation.sender_address).detail}
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        tokenLogin();
        getData()
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function isJSON(str) {
        try {
            return JSON.parse(str).detail;
        } catch (e) {
            return '-';
        }
    }
    const trackDelivery = async (resiNumber) => {
        try {
            setIsManifestLoading(true)
            const deliveryModel = new Delivery();

            const result = await deliveryModel.track(resiNumber);
            console.log('result', result.rajaongkir);

            if (result.rajaongkir.status.code === 400) {
                setManifest([]);
                return setIsManifestLoading(false);
            }

            const manifest = result.rajaongkir?.result?.manifest;

            if (manifest && Array.isArray(manifest)) {
                const manifestTemp = [];

                manifest.map(aManifest => {
                    manifestTemp.push({
                        label: aManifest.manifest_description,
                        content: moment(aManifest.manifest_date).format('DD MMM YYYY') + ', ' + aManifest.manifest_time
                    })
                })

                console.log('Manifest temp', manifestTemp)

                setManifest(manifestTemp)
                setIsManifestLoading(false)

            }

        } catch (e) {
            console.log(e)
        }
    }


    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`wrapped-tabpanel-${index}`}
                aria-labelledby={`wrapped-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
            <Header
                user={user}
                noOfPendingDonation={noOfPendingDonation}
                isSidebarShown={isSidebarShown}
                setSidebarShown={setSidebarShown}
            />

            <TrackingModal/>

            <Dialog
                maxWidth={"md"}
                fullWidth
                open={isModalVisible}
                onClose={() => {
                    setModalVisible(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{
                        width: '100%',
                        marginBottom: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <div style={{flex: 1}}>
                            Detail Pengiriman
                        </div>

                        <IconButton onClick={() => setModalVisible(false)}>
                            <AiOutlineClose/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label style={{fontSize: '.9em'}}>Tanggal Pembuatan Donasi</Form.Label>
                            <Form.Control disabled={true}
                                          value={moment(selectedDonation.created_at).format('DD MMM YYYY')}
                            />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label style={{fontSize: '.9em'}}>{user.loginAs === 'sender' ? 'Penerima' : 'Pengirim'}</Form.Label>
                            <Form.Control disabled={true}
                                          value={user.loginAs === 'sender' ? selectedDonation.receiver_full_name : selectedDonation.sender_full_name}
                            />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label style={{fontSize: '.9em'}}>Alamat</Form.Label>
                            <Form.Control disabled={true}
                                          value={user.loginAs === 'sender' ? isJSON(selectedDonation.receiver_address) : isJSON(selectedDonation.sender_address)}
                            />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1"
                                    style={{display: 'flex', flexDirection: 'column'}}>
                            <Form.Label style={{fontSize: '.9em'}}>Foto</Form.Label>

                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {selectedDonation.photo_urls?.map(url => (
                                    <img src={url} style={{
                                        width: 150,
                                        height: 150,
                                        objectFit: 'contain',
                                        backgroundColor: '#121212',
                                        marginRight: 15,
                                        marginBottom: 15
                                    }}/>
                                ))}
                            </div>

                        </Form.Group>
                    </DialogContentText>

                    {selectedDonation.message &&
                    <DialogContentText id="alert-dialog-description" style={{marginTop: 20}}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label style={{fontSize: '.9em'}}>Pesan</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          disabled={true}
                                          value={selectedDonation.message}
                            />
                        </Form.Group>
                    </DialogContentText>
                    }

                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 25, marginBottom: 10}}>
                        <Button
                            onClick={() => {
                                setModalVisible(false)
                                setTrackingModalVisible(true)
                                setSelectedResiNo(selectedDonation.tracking_number)

                                trackDelivery(selectedDonation.tracking_number)
                            }}
                            variant="contained"
                            color="primary"
                            style={{fontWeight: '700', minWidth: 100}}
                            className={classes.button}
                        >
                            Lacak
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Container style={{marginTop: 50}}>
                <Row style={{marginBottom: 20}}>
                    <Col style={{fontSize: '1.5em', fontWeight: '700', color: '#67904A'}}>
                        STATUS PENGIRIMAN
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Paper className={classes.root}>
                            <Tabs
                                centered
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={0} label="Dalam Perjalanan"/>
                                <Tab value={1} label="Terkirim"/>
                            </Tabs>
                        </Paper>
                    </Col>
                </Row>


                <TabPanel value={value} index={0}>
                    {
                        isLoading ?
                            <LoadingView/> :
                            pendingData.length === 0 ?
                                <NoDataView/> :
                                pendingData.map(datum => <DonationCard donation={datum}/>)
                    }
                </TabPanel>

                <TabPanel value={value} index={1}>
                    {
                        isLoading ?
                            <LoadingView/> :
                        acceptedData.length === 0 ?
                            <NoDataView/> :
                            acceptedData.map(datum => <DonationCard donation={datum}/>)
                    }
                </TabPanel>
            </Container>
        </Container>
    )
}
