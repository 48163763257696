import apiConfig from "./apiConfig";
// import GlobalData from "./GlobalData";

export default class ApiRequest {

    static set = async (endpoint, method, body) => {
        // console.log(`[${method}] ${apiConfig.base_url + endpoint}`)
        // console.log( "With Token : " + localStorage.getItem("token") )

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null,
                Accept: 'application/json',
            },
            body: JSON.stringify(body)
            // body: "afdadsf"
        });

        if(response.ok){

            return response.json()
        }else{
            let error = await response.json()

            console.log('error : ', error)

            // console.log(error)

            throw error

        }
    }

    static setMultipart = async (endpoint, method, body) => {
        // console.log(`token: ${GlobalData.token}`);

        console.log('API ACCESS: ' + endpoint);

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null,
                'Access-Control-Allow-Origin': '*',
            },
            body: body
            // body: "afdadsf"
        });

        if (response.ok) {
            return response.json()
        } else {

            let error = await response.json()

            console.log(error)

            throw error

        }
    }
}
