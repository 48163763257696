import React, {useState, useEffect} from "react";
import Container from "react-bootstrap/Container";
import {Col, Form, ProgressBar, Row, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";

import fullLogo from "../img/logo-full.jpg"
import Palette from "../util/Palette";

import {useHistory} from "react-router-dom";
import Map from "../reusable/Map";
import User from "../models/User"
import Button from "@material-ui/core/Button";
import swal from "sweetalert";


export default function LoginPage(props) {
    const userModel = new User();

    const history = useHistory()
    const [isLoading, setLoading] = useState(false);
    const [loginCred, setLoginCred] = useState({})
    const [loginAs, setLoginAs] = useState("sender");

    useEffect(() => {
        if (localStorage.getItem("token")) {
            history.push('/simpul')
        }
        console.log(localStorage.getItem("token"))
    }, [])

    const handleLogin = async () => {


        if (!loginCred.email || !loginCred.password) {
            swal("Login Gagal!", 'Harap mengisi form dengan lengkap.', "error");
        } else {
            try {
                setLoading(true)

                const result = await userModel.login({
                    ...loginCred,
                    loginAs
                })
                console.log('hasil login',result)
                setLoading(false)
                localStorage.setItem("token", result.token)
                localStorage.setItem("loginAs", result.loginAs)
                history.push('/simpul')
            } catch (e) {
                setLoading(false)

                if(e.code === "CREDENTIAL_NOT_FOUND") {
                    swal("Login Gagal!", 'Kombinasi alamat surel dan kata sandi salah.', "error");
                } else {
                    swal("Login Gagal!", 'Terjadi kesalahan.', "error");
                }
                console.log(e)
            }
        }

        // console.log(loginCred);
        // if (loginCred.email === 'user@user.com' && loginCred.password === 'useruser') {
        //     history.push("/map");
        // }else{
        //     alert('Username / Password Salah')
        // }
    }

    useEffect(() => {
        // const listener = event => {
        //     if (event.code === "Enter" || event.code === "NumpadEnter") {
        //         console.log("Enter key was pressed. Run your function.");
        //         event.preventDefault();
        //         handleLogin()
        //         // callMyFunction();
        //     }
        // };
        // document.addEventListener("keydown", listener);
        // return () => {
        //     document.removeEventListener("keydown", listener);
        // };
    }, []);

    return (
        <>
            <Container style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Row style={{display: "flex", justifyContent: "center"}}>
                    <Col md={5}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 20,
                            paddingBottom: 50
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: 20,
                                width: "90%"
                            }}>
                                <img
                                    style={{
                                        width: "100%",
                                        marginTop: 30,
                                        marginBottom: 30,
                                    }}
                                    src={fullLogo}/>

                                <Form style={{
                                    width: "100%",
                                }}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Alamat Surel</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleLogin()
                                                }
                                            }}
                                            value={loginCred.email} onChange={evt => setLoginCred({
                                            ...loginCred,
                                            email: evt.target.value
                                        })} type="email" placeholder="Alamat Surel"/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Kata Sandi</Form.Label>
                                        <Form.Control
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    handleLogin()
                                                }
                                            }}
                                            value={loginCred.password} onChange={evt => setLoginCred({
                                            ...loginCred,
                                            password: evt.target.value
                                        })} type="password" placeholder="Kata Sandi"/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Masuk Sebagai</Form.Label>
                                        <Form.Control as="select" value={loginAs} onChange={(e) => {
                                            setLoginAs(e.target.value)
                                        }}>
                                            <option value={"sender"}>Pengirim</option>
                                            <option value={"receiver"}>Penerima</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form>

                                <Button
                                    onClick={() => {
                                        handleLogin()
                                    }}
                                    style={{
                                        width: "100%", marginTop: 15
                                    }}
                                    variant={"contained"} disabled={isLoading} color="primary" type="submit">
                                    {isLoading ?
                                        <Spinner animation="border" size="sm"
                                                 style={{marginTop: 4, marginBottom: 4}}/> :
                                        "Masuk"}
                                </Button>

                                <div style={{marginTop: 30}}>
                                    Belum Punya Akun? <a href={'/#/daftar'} style={{fontWeight: '600', color: '#67904A'}}>Daftar sekarang</a>
                                </div>
                            </div>


                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    );
}
