import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Scrollbars } from "react-custom-scrollbars";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card, CardContent, Divider, Collapse, CardHeader, Avatar, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TrendingUp from '@material-ui/icons/TrendingUp';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));
export default function DonationInfo(props) {
    let { hide, windowWidth, shownInfoDonation, loadingExpandRanking, rankTotalDonation, rankMvpDonation, rankMvpReceiverDonation, rankLowReceiverDonation, rankLowDonation, setShownInfoDonation, allYears, year } = props;

    if(Object.keys(rankLowDonation).length === 0) {
        rankLowDonation = {provinceArr: []}
    }

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false)
    const [expandedLowDonation, setExpandedLowDonation] = useState(false);
    const [expandedLowReceiver, setExpandedLowReceiver] = useState(false);
    return (
        <>
            {
                shownInfoDonation ?
                    windowWidth > 1280 ?
                        <Card boxShadow={2} class="cardInfoDonation" style={{
                            position: 'absolute',
                            top: '53%',
                            zIndex: hide ? 0 : 100000,
                            right: '74px',
                            padding: '10px 10px 0px 10px',
                            margin: '10px 10px 20px 20px',
                            width: '35%',
                            background: '#ffffffe3',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'

                        }}>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <p style={{

                                }}>Statistik Donasi</p>
                            </div>
                            <Divider variant="middle" />
                            {
                                !loadingExpandRanking && rankLowReceiverDonation.provinceArr ?

                                    <Scrollbars style={{ width: '100%', height: '24.5vh' }}>
                                        <CardContent>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '45%' }}>
                                                    <p style={{

                                                        'fontSize': '0.8em'
                                                    }}>
                                                        Total Pengiriman Buku
                                                    </p>
                                                </div>

                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.8em',
                                                        'color': '#669048',
                                                        textAlign: 'right'
                                                    }}>
                                                        {rankTotalDonation}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '55%' }}>
                                                    <p style={{

                                                        'fontSize': '0.8em'
                                                    }}>
                                                        Daerah Pengirim Terbanyak
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.8em',
                                                        'marginLeft': 'auto',
                                                        'textAlign': 'right',
                                                        'color': '#669048'
                                                    }}>
                                                        {rankTotalDonation && rankMvpDonation.total > 0 && rankMvpDonation.province ? rankMvpDonation.province : ''} ({rankMvpDonation.total ? rankMvpDonation.total : '0'})
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '55%' }}>
                                                    <p style={{

                                                        'fontSize': '0.8em'
                                                    }}>
                                                        Provinsi Penerima Terbanyak
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.8em',
                                                        'color': '#669048',
                                                        textAlign: 'right'
                                                    }}>
                                                        {rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 && rankMvpReceiverDonation.province ? rankMvpReceiverDonation.province : ''}  ({rankMvpReceiverDonation.total_receiver ? rankMvpReceiverDonation.total_receiver : '0'})                          </p>
                                                </div>
                                            </div>


                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ minWidth: '50%' }}>
                                                    <p style={{

                                                        'fontSize': '0.8em'
                                                    }}>
                                                        Jumlah Provinsi Pengirim Terendah
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.8em',
                                                        'color': '#669048',
                                                        'textAlign': 'right'
                                                    }}>

                                                        {rankTotalDonation && rankMvpDonation.total > 0 && rankLowDonation.province ? rankLowDonation.provinceArr.length >= 4 ? rankLowDonation.provinceArr.slice(0, 4).join(", ") : rankLowDonation.province : ''}


                                                        {
                                                            rankLowDonation.provinceArr.length > 4 ? '' : `(${rankLowDonation.total})`
                                                        }

                                                        <Collapse in={expandedLowDonation} timeout="auto" unmountOnExit>
                                                            {rankLowDonation.provinceArr.slice(5, rankLowDonation.provinceArr.length).join(", ")}
                                                        </Collapse>
                                                        {
                                                            rankLowDonation.provinceArr.length <= 4 ? '' : `(${rankLowDonation.total})`
                                                        }
                                                        {rankTotalDonation && rankMvpDonation.total > 0 && rankLowDonation.provinceArr.length >= 4 ?
                                                            <IconButton
                                                                style={{
                                                                    padding: 0
                                                                }}
                                                                onClick={() => {
                                                                    setExpandedLowDonation(!expandedLowDonation);
                                                                }}
                                                                className={clsx(classes.expand, {
                                                                    [classes.expandOpen]: expandedLowDonation,
                                                                })}

                                                                aria-expanded={expandedLowDonation}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                            : ''}



                                                    </p>

                                                </div>
                                            </div>


                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ minWidth: '50%' }}>
                                                    <p style={{

                                                        'fontSize': '0.8em'
                                                    }}>
                                                        Jumlah Provinsi Penerima Terendah
                                                    </p>
                                                </div>

                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.8em',
                                                        'color': '#669048',
                                                        'textAlign': 'right'
                                                    }}>

                                                        {rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 ? rankLowReceiverDonation.provinceArr.length >= 4 ? rankLowReceiverDonation.provinceArr.slice(0, 4).join(", ") : rankLowReceiverDonation.province : ''}
                                                        {
                                                            rankLowReceiverDonation.provinceArr.length > 4 ? '' : `(${rankLowDonation.total_receiver})`
                                                        }

                                                        <Collapse in={expandedLowReceiver} timeout="auto" unmountOnExit>
                                                            {rankLowReceiverDonation.provinceArr.slice(5, rankLowReceiverDonation.provinceArr.length).join(", ")}
                                                        </Collapse>
                                                        {
                                                            rankLowReceiverDonation.provinceArr.length <= 4 ? '' : `(${rankLowReceiverDonation.total_receiver})`
                                                        }
                                                        {rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 && rankLowReceiverDonation.provinceArr.length >= 4 ?
                                                            <IconButton
                                                                style={{
                                                                    padding: 0
                                                                }}
                                                                onClick={() => {
                                                                    setExpandedLowReceiver(!expandedLowReceiver);
                                                                }}
                                                                className={clsx(classes.expand, {
                                                                    [classes.expandOpen]: expandedLowReceiver,
                                                                })}

                                                                aria-expanded={expandedLowReceiver}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                            : ''}
                                                    </p>

                                                </div>
                                            </div>
                                        </CardContent>
                                    </Scrollbars>


                                    : <CardContent>
                                        <CircularProgress style={{
                                            margin: 'auto',
                                            marginTop: 10,
                                            textAlign: 'center',
                                            display: 'block'
                                        }} disableShrink />
                                    </CardContent>
                            }


                        </Card> :
                        <Card boxShadow={2} class="cardInfoDonationMini" style={{
                            position: 'absolute',
                            top: '55%',
                            zIndex: hide ? 0 : 100000,
                            left: '50px',
                            padding: '10px 10px 0px 10px',
                            margin: '10px 10px 20px 20px',
                            width: '55%',


                            background: '#ffffffe3',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                            transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'

                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <p style={{

                                }}>Statistik Donasi</p>
                            </div>
                            <Divider variant="middle" />
                            {
                                !loadingExpandRanking ?
                                    <Scrollbars style={{ width: '100%', height: '20vh' }}>
                                        <CardContent>


                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '45%' }}>
                                                    <p style={{

                                                        'fontSize': '0.7em'
                                                    }}>
                                                        Total Pengiriman Buku
                                                    </p>
                                                </div>

                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.7em',
                                                        'color': '#669048',
                                                        textAlign: 'right'
                                                    }}>
                                                        {rankTotalDonation}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '55%' }}>
                                                    <p style={{

                                                        'fontSize': '0.7em'
                                                    }}>
                                                        Daerah Pengirim Terbanyak
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.7em',
                                                        'color': '#669048',
                                                        textAlign: 'right'
                                                    }}>
                                                        {rankTotalDonation && rankMvpDonation.total > 0 && rankMvpDonation.province ? rankMvpDonation.province : ''} ({rankMvpDonation.total ? rankMvpDonation.total : '0'})
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ width: '55%' }}>
                                                    <p style={{
                                                        'fontSize': '0.7em'
                                                    }}>
                                                        Provinsi Penerima Terbanyak
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.7em',
                                                        'color': '#669048',
                                                        textAlign: 'right'
                                                    }}>
                                                        {rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 && rankMvpReceiverDonation.province ? rankMvpReceiverDonation.province : ''}  ({rankMvpReceiverDonation.total_receiver ? rankMvpReceiverDonation.total_receiver : '0'})                          </p>
                                                </div>
                                            </div>


                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ minWidth: '50%' }}>
                                                    <p style={{
                                                        'fontSize': '0.7em'
                                                    }}>
                                                        Jumlah Provinsi Pengirim Terendah
                                                    </p>
                                                </div>
                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.7em',
                                                        'color': '#669048',
                                                        'textAlign': 'right'
                                                    }}>
                                                        {rankLowDonation.provinceArr && rankTotalDonation && rankMvpDonation.total > 0 && rankLowDonation.province ? rankLowDonation.provinceArr.length >= 4 ? rankLowDonation.provinceArr.slice(0, 4).join(", ") : rankLowDonation.province : ''}
                                                        {
                                                            rankLowDonation.provinceArr && rankLowDonation.provinceArr.length > 4 ? '' : `(${rankLowDonation.total})`
                                                        }
                                                        <Collapse in={expandedLowDonation} timeout="auto" unmountOnExit>
                                                            {rankLowDonation.provinceArr.slice(5, rankLowDonation.provinceArr.length).join(", ")}
                                                        </Collapse>
                                                        {
                                                            rankLowDonation.provinceArr && rankLowDonation.provinceArr.length <= 4 ? '' : `(${rankLowDonation.total})`
                                                        }
                                                        {rankLowDonation.provinceArr && rankTotalDonation && rankMvpDonation.total > 0 && rankLowDonation.provinceArr.length >= 4 ?
                                                            <IconButton
                                                                style={{
                                                                    padding: 0
                                                                }}
                                                                onClick={() => {
                                                                    setExpandedLowDonation(!expandedLowDonation);
                                                                }}
                                                                className={clsx(classes.expand, {
                                                                    [classes.expandOpen]: expandedLowDonation,
                                                                })}

                                                                aria-expanded={expandedLowDonation}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                            : ''}
                                                    </p>
                                                </div>
                                            </div>


                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ minWidth: '50%' }}>
                                                    <p style={{

                                                        'fontSize': '0.7em'
                                                    }}>
                                                        Jumlah Provinsi Penerima Terendah
                                                    </p>
                                                </div>

                                                <div style={{ width: 'auto', 'marginLeft': 'auto' }}>
                                                    <p style={{
                                                        'fontWeight': 800,
                                                        'fontSize': '0.7em',
                                                        'color': '#669048',
                                                        'textAlign': 'right'

                                                    }}>
                                                        {rankLowReceiverDonation.provinceArr && rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 ? rankLowReceiverDonation.provinceArr.length >= 4 ? rankLowReceiverDonation.provinceArr.slice(0, 4).join(", ") : rankLowReceiverDonation.province : ''}
                                                        {
                                                            rankLowReceiverDonation.provinceArr && rankLowReceiverDonation.provinceArr.length > 4 ? '' : `(${rankLowDonation.total_receiver})`
                                                        }
                                                        <Collapse in={expandedLowReceiver} timeout="auto" unmountOnExit>
                                                            {rankLowReceiverDonation.provinceArr && rankLowReceiverDonation.provinceArr.slice(5, rankLowReceiverDonation.provinceArr.length).join(", ")}
                                                        </Collapse>
                                                        {
                                                            rankLowReceiverDonation.provinceArr && rankLowReceiverDonation.provinceArr.length <= 4 ? '' : `(${rankLowReceiverDonation.total_receiver})`
                                                        }
                                                        {rankTotalDonation && rankMvpReceiverDonation.total_receiver > 0 && rankLowReceiverDonation.provinceArr && rankLowReceiverDonation.provinceArr.length >= 4 ?
                                                            <IconButton
                                                                style={{
                                                                    padding: 0
                                                                }}
                                                                onClick={() => {
                                                                    setExpandedLowReceiver(!expandedLowReceiver);
                                                                }}
                                                                className={clsx(classes.expand, {
                                                                    [classes.expandOpen]: expandedLowReceiver,
                                                                })}

                                                                aria-expanded={expandedLowReceiver}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                            : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Scrollbars> :
                                    <CircularProgress style={{
                                        margin: 'auto',
                                        marginTop: 10,
                                        marginBottom: 10,
                                        textAlign: 'center',
                                        display: 'block'
                                    }} disableShrink />
                            }


                        </Card> : ''
            }



            {
                windowWidth > 1280 ? <IconButton

                    className={shownInfoDonation ? "btnInfoDonation" : ''}
                    onClick={() => {

                        setShownInfoDonation(!shownInfoDonation)
                    }}
                    variant={"contained"} size={'small'} color="default" style={{
                        right: '2%',
                        position: 'absolute',
                        top: '55%',
                        zIndex: hide ? 0 : 10000,
                        borderRadius: '100%',
                        height: '50px',
                        width: '50px',

                        background: '#ffffffeb',
                        transition: 'background-color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        transitionProperty: 'background-color, box-shadow, border',
                        transitionDuration: '250ms, 250ms, 250ms',
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDelay: '0ms, 0ms, 0ms',
                        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'

                    }}>
                    <TrendingUp style={{
                        'color': 'rgb(138 138 137)',
                        'width': '1.5em',
                        'height': '1.5em'
                    }
                    } ><small>Info Donasi</small></TrendingUp>
                </IconButton> : <IconButton
                    className={shownInfoDonation ? "btnInfoDonation" : ''}
                    onClick={() => {
                        setShownInfoDonation(!shownInfoDonation)
                    }}
                    variant={"contained"} color="default" borderRadius="50%" size={'small'} style={{
                        left: '2%',
                        position: 'absolute',
                        top: '56%',
                        zIndex: hide ? 0 : 10000,
                        borderRadius: '100%',
                        height: '50px',
                        width: '50px',
                        background: '#ffffffeb',
                        transition: 'background-color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        transitionProperty: 'background-color, box-shadow, border',
                        transitionDuration: '250ms, 250ms, 250ms',
                        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
                        transitionDelay: '0ms, 0ms, 0ms',
                        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                    }}>
                    <TrendingUp style={{
                        'color': 'rgb(138 138 137)',
                        'width': '1em',
                        'height': '1em'
                    }
                    } ><small>Info Donasi</small></TrendingUp>
                </IconButton >
            }
        </>
    )
}
