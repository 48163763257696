import Map from "../reusable/Map";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fullLogo from "../img/logo-full.jpg";
import {Form, Spinner, Alert} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from '@material-ui/core/Button';
import Palette from "../util/Palette";
import User from "../models/User";
import {useHistory} from "react-router-dom";
import Sender from "../models/Sender";
import Receiver from "../models/Receiver";
import {AiOutlineClose} from "react-icons/ai";
import {makeStyles} from "@material-ui/core/styles";
import {FaInfoCircle} from "react-icons/fa";
import Util from "../models/Util";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import {BiLeftArrowAlt, HiOutlineChevronLeft} from "react-icons/all";
import swal from "sweetalert";

export default function ProfilePage(props) {
    const utilModel = new Util();
    const [isLoading, setLoading] = useState(false);

    const history = useHistory()

    const userModel = new User();
    const senderModel = new Sender();
    const receiverModel = new Receiver();

    const [updateData, setUpdateData] = useState({});

    const [address, setAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [provinces, setProvinces] = useState([])
    const [images, setImages] = useState([])
    const [prevProvince, setPrevProvince] = useState(null);
    const [isUploadingImage, setUploadingImage] = useState(false)

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProvinces()
            tokenLogin()

        } else {
            history.push("/masuk")
        }
    }, [])

    const getProvinces = async () => {
        const result = await userModel.getAllProvince();
        setProvinces(result)
    }

    const directChangePassword = () => {
        history.push("/ubah-kata-sandi")
    }

    useEffect(() => {
        console.log('updateData', updateData)
    }, [updateData])

    const tokenLogin = async () => {
        try {
            const user = await userModel.tokenLogin();

            console.log('user', user)
            setPrevProvince(user.province)
            setAddress(user.address.detail)
            setLatitude(user.address.latitude)
            setLongitude(user.address.longitude)
            setUpdateData(user)
            provinces.forEach(value => {
                if (value.id == user.province) {
                    user.province = value.name
                }
            });
            if (user.photo_urls) {
                setImages(JSON.parse(user.photo_urls))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpdate = async () => {
        if (!updateData.address.latitude || !updateData.address.longitude) {
            swal("Sunting Profil Gagal!", 'Alamat tidak valid, silahkan pilih alamat menggunakan marker yang pada peta.', "error");
        }
        if (!updateData.email || !updateData.full_name || !updateData.phone_number) {
            swal("Sunting Profil Gagal!", 'Harap mengisi form dengan lengkap.!', "error");
        } else {
            setLoading(true)

            try {
                let result;

                if (updateData.province == 'Kepulauan Bangka Belitung') {
                    updateData.province = 'Bangka Belitung'
                }
                console.log(updateData.province);
                let province_id;
                provinces.forEach(value => {
                    if (value.id == updateData.province || value.name == updateData.province) {
                        updateData.province = value.id;
                        province_id = value.id
                    }
                });

                if (province_id == undefined) {
                    swal("Sunting Profil Gagal!", 'Alamat tidak valid, silahkan pilih alamat menggunakan marker yang pada peta.', "error")
                    setLoading(false)
                    return
                }

                const body = {
                    ...updateData,
                    photo_urls: JSON.stringify(images),
                    address: JSON.stringify({
                        detail: address,
                        latitude,
                        longitude
                    })
                }

                if (updateData.loginAs === 'sender') {
                    result = await senderModel.update(updateData.id, body)
                } else if (updateData.loginAs === 'receiver') {
                    result = await receiverModel.update(updateData.id, body)
                }

                setLoading(false)

                if (result.success) {
                    swal('Sunting Profil Berhasil!', "Profil berhasil diperbarui.'", 'success')
                    .then((value) => {
                        history.push("/")
                    });
            
                }
            
            } catch (e) {
                setLoading(false)
                if (e.msg) {
                    swal("Sunting Profil Gagal!", e.msg, "error");
                } else {
                    swal("Sunting Profil Gagal!", 'Kesalahan terjadi.', "error");
                }
                console.log(e)
            }
        }
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }));

    const classes = useStyles();

    const uploadImage = async (image, purpose) => {
        try {
            const response = await utilModel.uploadImage(image, purpose)

            return response
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Container style={{
            minHeight: "100vh",
            paddingTop: 30,
            paddingBottom: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Row style={{display: "flex", justifyContent: "center"}}>
                <Col md={5}>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 20,
                        paddingBottom: 50
                    }}>
                        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                            <IconButton onClick={() => {
                                history.push('/')
                            }}>
                                <BiLeftArrowAlt color={'grey'}
                                         style={{cursor: 'pointer'}}
                                         size={30}
                                />
                            </IconButton>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 20,
                            paddingTop: 0,
                            width: "90%"
                        }}>
                            <img
                                style={{
                                    width: "100%",
                                    marginTop: 15,
                                    marginBottom: 30,
                                }}
                                src={fullLogo}/>

                            <Form style={{
                                width: "100%",
                            }}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Alamat Surel</Form.Label>
                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        value={updateData.email} onChange={evt => setUpdateData({
                                        ...updateData,
                                        email: evt.target.value
                                    })} type="email" placeholder="Alamat Surel"/>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Nama</Form.Label>
                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        value={updateData.full_name} onChange={evt => setUpdateData({
                                        ...updateData,
                                        full_name: evt.target.value
                                    })} placeholder="Nama"/>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Nomor Telepon</Form.Label>
                                    <Form.Control
                                        type={'tel'}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        value={updateData.phone_number} onChange={evt => setUpdateData({
                                        ...updateData,
                                        phone_number: evt.target.value
                                    })} placeholder="Nomor Telepon"/>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" style={{marginBottom: 55}}>
                                    <Form.Label>Alamat</Form.Label>
                                    <Map
                                        address={address}
                                        onDataChanged={(data) => {
                                            setUpdateData({
                                                ...updateData,
                                                province: data.state ? data.state : null
                                            })
                                            setLatitude(data.mapPosition.lat)
                                            setLongitude(data.mapPosition.lng)
                                            setAddress(data.address)
                                        }}
                                        google={props.google}
                                        center={{
                                            lat: latitude ? latitude : -6.2297419,
                                            lng: longitude ? longitude : 106.759478
                                        }}
                                        height='250px'
                                        zoom={15}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        style={{
                                            height: 100
                                        }}
                                        as={'textarea'}
                                        value={address} disabled={true} type="email" placeholder="Alamat"/>
                                </Form.Group>
                                <Alert variant='warning'>
                                    <FaInfoCircle></FaInfoCircle> Apabila alamat tidak ditemukan, Anda dapat menggunakan
                                    marker yang ada di peta
                                </Alert>

                                {updateData.loginAs === 'receiver' &&
                                <Form.Group controlId="formBasicEmail" style={{marginBottom: 55}}>
                                    <Form.Label>Unggah Foto Kegiatan</Form.Label>

                                    <div className={classes.root}>
                                        <input
                                            accept="image/*"
                                            onChange={async (e) => {
                                                setUploadingImage(true)

                                                const imagesTemp = [...images];
                                                const response = await uploadImage(e.target.files[0], 'donasi')

                                                imagesTemp.push(response.location)

                                                setImages(imagesTemp)

                                                setUploadingImage(false)
                                            }}
                                            className={classes.input}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" color="primary" component="span"
                                                    disabled={isUploadingImage}>
                                                {isUploadingImage ?
                                                    <Spinner animation="border" size="sm"
                                                             style={{
                                                                 marginTop: 4,
                                                                 marginBottom: 4,
                                                                 marginLeft: 20,
                                                                 marginRight: 20
                                                             }}/> :
                                                    'Unggah'
                                                }
                                            </Button>
                                        </label>

                                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                            {
                                                images.map((image, idx) => {
                                                    return (
                                                        <div style={{position: 'relative'}}>
                                                            <div style={{position: 'absolute', top: 0, right: 15}}>
                                                                <AiOutlineClose
                                                                    onClick={() => {
                                                                        const imagesTemp = [...images];

                                                                        imagesTemp.splice(idx, 1);

                                                                        setImages(imagesTemp)
                                                                    }}
                                                                    color={'white'} size={20}
                                                                    style={{cursor: 'pointer'}}/>
                                                            </div>

                                                            <img src={image} style={{
                                                                width: 150,
                                                                height: 150,
                                                                objectFit: 'contain',
                                                                backgroundColor: '#121212',
                                                                marginRight: 15,
                                                                marginBottom: 15
                                                            }}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Form.Group>
                                }

                            </Form>

                            <Button
                                variant="contained" color="primary" component="span"
                                onClick={() => {
                                    handleUpdate()
                                }}
                                style={{
                                    width: '100%'
                                }}
                                disabled={isLoading}
                                type="submit">
                                {isLoading ?
                                    <Spinner animation="border" size="sm"
                                             style={{marginTop: 4, marginBottom: 4}}/> :
                                    "Simpan"}
                            </Button>
                            <Button variant="contained" onClick={() => {
                                directChangePassword()

                            }
                            } color="primary"
                                    variant="outlined" style={{
                                width: '100%', marginTop: '10px'
                            }} component="span">
                                Ubah Kata Sandi
                            </Button>
                        </div>


                    </Card>

                </Col>
            </Row>
        </Container>
    )
}
