import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { GoogleMapsAPI } from '../client-config';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

Geocode.setLanguage("id");
Geocode.setRegion("id");
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
Geocode.setLocationType("GEOMETRIC_CENTER");
class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      address: '',
      city: '',
      state: '',
      postalCode: '',
      kecamatan: '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
    }
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          state = this.getState(addressArray),
          postalCode = this.getPostalCode(addressArray),
          kecamatan = this.getKecamatan(addressArray);

        if (this.props.onDataChanged) {
          this.props.onDataChanged({
            address: (address) ? address : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            postalCode: (postalCode) ? postalCode : '',
            kecamatan: (kecamatan) ? kecamatan : '',
            markerPosition: {
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng
            },
            mapPosition: {
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng
            },
          })
        }

        this.setState({
          address: (address) ? address : '',
          city: (city) ? city : '',
          state: (state) ? state : '',
          postalCode: (postalCode) ? postalCode : '',
          kecamatan: (kecamatan) ? kecamatan : ''
        })
      },
      error => {
        console.error(error);
      }
    );
  };

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {

    if (
      this.state.markerPosition.lat !== nextProps.center.lat ||
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address
    ) {
      // if (this.state.markerPosition.lat !== nextProps.center.lat) {
      this.setState({
        markerPosition: {
          lat: nextProps.center.lat,
          lng: nextProps.center.lng
        },
        mapPosition: {
          lat: nextProps.center.lat,
          lng: nextProps.center.lng
        },
        address: nextProps.address,
      })
      // }

      return true
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false
    }
  }

  /**
   * Get the postal code and set the postal code input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getPostalCode = (addressArray) => {
    let postalCode = '';
    if(addressArray !== undefined){
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
          postalCode = addressArray[i].long_name;
          return postalCode;
        }
      }
    }

  };

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = '';
    if(addressArray !== undefined){
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    }

  };

  /**
   * Get the kecamatan and set the kecamatan input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getKecamatan = (addressArray) => {
    let kecamatan = '';
    if(addressArray !== undefined){
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_3' === addressArray[i].types[0]) {
          kecamatan = addressArray[i].long_name;
          return kecamatan;
        }
      }
    }

  };

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = '';
    if(addressArray !== undefined){
      for (let i = 0; i < addressArray.length; i++) {
        for (let i = 0; i < addressArray.length; i++) {
          if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
            state = addressArray[i].long_name;
            return state;
          }
        }
      }
    }

  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    console.log('event change', event)
    this.setState({[event.target.name]: event.target.value});
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {

  };

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          postalCode = this.getPostalCode(addressArray),
          state = this.getState(addressArray),
          kecamatan = this.getKecamatan(addressArray);

        if (this.props.onDataChanged) {
          this.props.onDataChanged({
            address: (address) ? address : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            postalCode: (postalCode) ? postalCode : '',
            kecamatan: (kecamatan) ? kecamatan : '',
            markerPosition: {
              lat: newLat,
              lng: newLng
            },
            mapPosition: {
              lat: newLat,
              lng: newLng
            },
          })
        }

        this.setState({
          address: (address) ? address : '',
          city: (city) ? city : '',
          state: (state) ? state : '',
          postalCode: (postalCode) ? postalCode : '',
          kecamatan: (kecamatan) ? kecamatan : '',
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          },
        })
      },
      error => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */

  getrandomSelected = (addresses) => {
    let latValue = addresses.geometry
   // let lngValue = addresses.geometry.location.lng();
    console.log('latval', latValue)
  }

  onPlaceSelected = (place) => {
    console.log('isinya', place)
    if(place.address_components){
      const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      state = this.getState(addressArray),
      postalCode = this.getPostalCode(addressArray),
      kecamatan = this.getKecamatan(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.

    if (this.props.onDataChanged) {
      this.props.onDataChanged({
        address: (address) ? address : '',
        city: (city) ? city : '',
        state: (state) ? state : '',
        postalCode: (postalCode) ? postalCode : '',
        kecamatan: (kecamatan) ? kecamatan : '',
        markerPosition: {
          lat: latValue,
          lng: lngValue
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue
        },
      })
    }

    this.setState({
      address: (address) ? address : '',
      city: (city) ? city : '',
      state: (state) ? state : '',
      postalCode: (postalCode) ? postalCode : '',
      kecamatan: (kecamatan) ? kecamatan : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      },
    })
    }

  };


  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap(
        props => (
          <GoogleMap google={this.props.google}
                     defaultZoom={this.props.zoom}
                     defaultCenter={{lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng}}
          >
            {/* InfoWindow on top of marker */}
            {/*<MarkerWithInfoWindow position={} content={this.state.address} />*/}

            <Marker
              draggable={!this.props.minimum && true}
              onDragEnd={this.onMarkerDragEnd}
              position={{
                lat: (this.state.markerPosition.lat),
                lng: this.state.markerPosition.lng
              }}>
              {!this.props.minimum &&
              <InfoWindow>
                <div>
                  <span style={{padding: 0, margin: 0}}>{this.state.address}</span>
                </div>
              </InfoWindow>
              }
            </Marker>

            {/*<InfoWindow*/}
            {/*    onClose={this.onInfoWindowClose}*/}
            {/*    position={{*/}
            {/*        lat: (this.state.markerPosition.lat + 0.0018),*/}
            {/*        lng: this.state.markerPosition.lng*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <span style={{padding: 0, margin: 0}}>{this.state.address}</span>*/}
            {/*    </div>*/}
            {/*</InfoWindow>*/}
            {/*Marker*/}
            {/*<Marker google={this.props.google}*/}
            {/*        name={'Dolores park'}*/}
            {/*        draggable={true}*/}
            {/*        onDragEnd={this.onMarkerDragEnd}*/}
            {/*        position={{lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng}}*/}
            {/*/>*/}
            {/*<Marker/>*/}
            {/* For Auto complete Search Box */}
            {!this.props.minimum &&
            <Autocomplete
              style={{
                width: '100%',
                height: '40px',
                paddingLeft: '16px',
                marginTop: 5,
                marginBottom: 5,
              }}
              placeholder={'Cari Lokasi...'}
              onPlaceSelected={this.onPlaceSelected}
              onChange={(e) => this.getrandomSelected(e.target.value)}
              types={['address']}
              componentRestrictions={{country: "id"}}
            />
            }
          </GoogleMap>
        )
      )
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map =
        <div>
          {/*<div>*/}
          {/*    <div className="form-group">*/}
          {/*        <label htmlFor="">City</label>*/}
          {/*        <input type="text" name="city" className="form-control" onChange={this.onChange}*/}
          {/*               readOnly="readOnly" value={this.state.city}/>*/}
          {/*    </div>*/}
          {/*    <div className="form-group">*/}
          {/*        <label htmlFor="">Provinsi</label>*/}
          {/*        <input type="text" name="state" className="form-control" onChange={this.onChange}*/}
          {/*               readOnly="readOnly" value={this.state.state}/>*/}
          {/*    </div>*/}
          {/*    <div className="form-group">*/}
          {/*        <label htmlFor="">Address</label>*/}
          {/*        <input type="text" name="address" className="form-control" onChange={this.onChange}*/}
          {/*               readOnly="readOnly" value={this.state.address}/>*/}
          {/*    </div>*/}

          {/*    <div className="form-group">*/}
          {/*        <label htmlFor="">Postal Code</label>*/}
          {/*        <input type="text" name="address" className="form-control" onChange={this.onChange}*/}
          {/*               readOnly="readOnly" value={this.state.postalCode}/>*/}
          {/*    </div>*/}

          {/*    <div className="form-group">*/}
          {/*        <label htmlFor="">Kecamatan</label>*/}
          {/*        <input type="text" name="address" className="form-control" onChange={this.onChange}*/}
          {/*               readOnly="readOnly" value={this.state.kecamatan}/>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={
              <div style={{height: `100%`}}/>
            }
            containerElement={
              <div style={{height: this.props.height}}/>
            }
            mapElement={
              <div style={{height: `100%`, borderRadius: 8}}/>
            }
          />
        </div>
    } else {
      map = <div style={{height: this.props.height}}/>
    }
    return (map)
  }
}

export default Map
