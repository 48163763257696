import React, { useEffect, useState } from "react";
import { Map, TileLayer, Polyline, Marker } from "react-leaflet";
import FlightCluster from "../FlightCluster";
import L from "leaflet";
import { useLeaflet } from "react-leaflet";
import LocationDetailSidebar from "../components/LocationDetailSidebar";
import { customMarker } from "../constants";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ButtonGroup, Form, Spinner } from "react-bootstrap"
import { makeStyles } from '@material-ui/core/styles';

import { AiOutlineClose } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import fullLogo from "../img/logo-full.jpg";
import Row from "react-bootstrap/Row";
import Receiver from "../models/Receiver";
import User from "../models/User";
import Donation from "../models/Donation";
import moment from "moment";
import { HiOutlineLogin, IoMdExit } from "react-icons/all";
import Autosuggest from 'react-autosuggest';
import { HiOutlineSearch } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { GiHamburgerMenu } from "react-icons/gi";
import MarkerCluster from "../MarkerCluster";
import { GoogleMapsAPI } from "../client-config";
import ExportedDonation from "../models/ExportedDonation";
import { Scrollbars } from "react-custom-scrollbars";
import { BiChevronDown, BiChevronLeft, BiChevronRight, BiChevronUp } from "react-icons/bi";
import { BsFillBarChartFill, BsDash } from "react-icons/bs";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Header from "../reusable/Header";
import LegendInfo from "../reusable/LegendInfo";
import DonationInfo from "../reusable/DonationInfo";

const mapStyle = { height: "calc(100vh - 78px)" };

require('leaflet.animatedmarker/src/AnimatedMarker');

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));
// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
//     input: {
//         display: 'none',
//     },
//     button: {
//         margin: theme.spacing(1),
//     },
// }));
export default function MapPage(props) {
    const [yearsChoice, setYearsChoice] = useState([2017, 2018, 2019, 2020, 2021])
    const [isSearchMode, setSearchMode] = useState(false);

    const exportedDonationModel = new ExportedDonation();
    const [isSidebarShown, setSidebarShown] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)


    const [map, setMap] = useState(null);

    const userModel = new User();
    const donationModel = new Donation();
    const receiverModel = new Receiver();
    const [acceptedDonation, setAcceptedDonation] = useState([])
    const [senderDonation, setSenderDonation] = useState([])
    const [receiverDonation, setReceiverDonation] = useState([])
    const [donationClusterGroup, setDonationClusterGroup] = useState([])
    const [receiverClusterGroup, setReceiverClusterGroup] = useState([])
    const [isLoading, setLoading] = useState(false);

    const [exceptProvince, setExceptProvince] = useState([])

    const [user, setUser] = useState({})

    const classes = useStyles();
    const [test, setTest] = useState([])
    const [filteredReceivers, setFilteredReceivers] = useState([]);

    const [message, setMessage] = useState('')
    const [trackingNumber, setTrackingNumber] = useState('')

    const [isInstructionVisible, setInstructionVisible] = useState(true);
    const [instructionType, setInstructionType] = useState('sender');

    const [isDonateBookVisible, setDonateBookVisible] = useState(false)

    const [position, setPosition] = useState([-1.8, 115]);
    const [detail, setDetail] = useState(null);
    const [selectedReceiverId, setSelectedReceiverId] = useState({})

    const [base64Images, setBase64Images] = useState([])

    const [flightClusterData, setFlightClusterData] = useState([]);
    const [filteredFlightClusterData, setFilteredFlightClusterData] = useState([]);

    const [receivers, setReceivers] = useState([]);
    const [isSenderShown, setSenderShown] = useState(true);
    const [isReceiverShown, setReceiverShown] = useState(true);

    const [year, setYear] = useState(2021);
    const [changeYear, setChangeYear] = useState(false);

    const [noOfPendingDonation, setNoOfPendingDonation] = useState(0);
    const [exportedDonation, setExportedDonation] = useState([])
    const [shownInfoDonation, setShownInfoDonation] = useState(true)
    const [shownLegendDonation, setShownLegendDonation] = useState(true)
    const [expanded, setExpanded] = useState(false)

    const [loadingExpandRanking, setLoadingExpandRanking] = useState(false)
    const iconCloseBook = new L.Icon({
        iconUrl: '/images/buku_tertutup.png',
        iconSize: new L.Point(50, 45),
    });

    const iconOpenBook = new L.Icon({
        iconUrl: '/images/buku_terbuka.png',
        iconSize: new L.Point(50, 45),
    });

    const [allYears, setAllYears] = useState(true)

    //====================== ranking state ==============================
    const [rankTotalDonation, setRankTotalDonation] = useState(0)
    const [rankMvpDonation, setRankMvpDonation] = useState({})
    const [rankLowDonation, setRankLowDonation] = useState({})
    const [rankMvpReceiverDonation, setRankMvpReceiverDonation] = useState({})
    const [rankLowReceiverDonation, setRankLowReceiverDonation] = useState({})
    const handleResize = () => {
        console.log('window.innerWidth', window.innerWidth)

        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    useEffect(() => {
        if (map) {
            // console.log('donationClusterGroup', donationClusterGroup)

            if (!isSenderShown) {
                map.eachLayer(function (layer) {
                    if (layer["_featureGroup"]) {
                        map.removeLayer(layer)
                    }

                    // console.log('layer', layer)
                    if (layer['_icon']) {
                        if (layer['_icon'].outerHTML.includes('buku_tertutup') || layer['_icon'].outerHTML.includes('clusterSender')) {
                            map.removeLayer(layer)
                        }
                    }
                });
            }

            if (!isReceiverShown) {
                map.eachLayer(function (layer) {
                    if (layer["_featureGroup"]) {
                        map.removeLayer(layer)
                    }

                    if (layer['_icon'] && (layer['_icon'].outerHTML.includes('buku_terbuka') || layer['_icon'].outerHTML.includes('clusterReceiver'))) {
                        map.removeLayer(layer)
                    }
                });
            }

        }
    }, [map, isSenderShown, isReceiverShown])


    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const tokenLogin = async () => {
        try {
            const user = await userModel.tokenLogin();

            setUser(user)
        } catch (e) {
            console.log(e)
        }
    }

    const getNoOfPendingDonation = async () => {
        try {
            const result = await donationModel.getPending();

            setNoOfPendingDonation(result[0].count_pending_donations)
        } catch (e) {
            console.log(e)
        }
    }

    const getExportedDonation = async () => {
        try {
            const result = await exportedDonationModel.getAll();

            setExportedDonation(result)

            return result;
        } catch (e) {
            console.log(e)

            return [];
        }
    }

    useEffect(() => {
        getReceivers()

        getExportedDonation();
        if (localStorage.getItem("token")) {
            tokenLogin()
            getNoOfPendingDonation()
        }
        getAcceptedDonation()

        // navigator.geolocation.getCurrentPosition(function(position) {
        //
        //     console.log("Latitude is :", position.coords.latitude);
        //
        //     console.log("Longitude is :", position.coords.longitude);
        //
        // });
        if (!localStorage.getItem('isInstructionShown') && !localStorage.getItem("token")) {
            setTimeout(() => {
                localStorage.setItem('isInstructionShown', 'true')
                setInstructionVisible(true)
            }, 30000)
        }
    }, [])

    const getReceivers = async () => {
        try {
            const receivers = await receiverModel.getAll();
            setReceivers(receivers)

        } catch (e) {
            console.log(e)
        }
    }


    const getAcceptedDonation = async () => {
        try {
            const donation = await donationModel.getDonationData(year);

            // console.log('donation', donation)

            setAcceptedDonation(donation)
        } catch (e) {
            // console.log(e)
        }
    }


    useEffect(() => {
        if (receivers.length > 0 && acceptedDonation.length > 0) {
            const receiver_province = receivers.map((x) => {
                return x.province
            });

            let arr_receiver = [];
            for (const province of mergeArray(receiver_province)) {
                let x = receivers.filter((element) => {
                    return element.province === province;
                });
                let obj = {
                    'province': province,
                    'data': x
                }
                arr_receiver.push(obj)
            }

            setReceiverDonation(arr_receiver)
            let filteredUniqueSender = acceptedDonation.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.sender_id === value.sender_id
                ))
            )

            const sender_province = acceptedDonation.map((x) => {
                return x.sender_province
            });

            let arr_sender = [];
            for (const province of mergeArray(sender_province)) {
                let x = filteredUniqueSender.filter((element) => {
                    return element.sender_province === province;
                });
                let obj = {
                    'province': province,
                    'data': x
                }
                arr_sender.push(obj)
            }
            setSenderDonation(arr_sender)
        }

    }, [receivers, acceptedDonation, exportedDonation])


    const mergeArray = (arr) => {
        const grp = arr.reduce((r, c, i, a) => {
            r[c] = [...r[c] || [], c]
            r[c] = (a[i + 1] != c && r[c].length == 1) ? r[c][0] : r[c]
            return r
        }, {})
        return Object.keys(grp);
    }


    useEffect(() => {
        let arr_sender = [];
        let arr_receiver = [];
        const senderIds = []
        const receiverIds = []
        let exceptProvinces = []

        senderDonation.forEach((donation, index, array) => {

            let xn = donation.data.map(function (x, keydonation, arrays) {
                let total_sender_donation_red = arrays.reduce((a, e) => {
                    a[e.sender_id] = ++a[e.sender_id] || 0;
                    return a;
                }, {});
                let total_sender_donation = arrays.filter(e => total_sender_donation_red[e.sender_id]).length


                let donationPhotos = [];

                if (x.donation_photo_urls) {
                    donationPhotos = JSON.parse(x.donation_photo_urls)
                }

                let address = JSON.parse(x.sender_address);
                let xx = {
                    'latitude': address.latitude,
                    'longitude': address.longitude,
                    'name': x.sender_full_name,
                    'address': address.detail,
                    'phoneNo': x.sender_phone_number,
                    'image': donationPhotos.length === 0 ? null : donationPhotos,
                    'noOfBooks': donationPhotos.length,
                    'totalDonation': total_sender_donation === 0 ? 1 : total_sender_donation,
                    sender: true
                };
                let cl = setPropertiesMarker(xx, iconCloseBook);

                return cl;
            })

            // console.log('xn', xn)



            if (exportedDonation.senders) {
                exportedDonation.senders?.forEach((sender) => {
                    if (sender.sender_province == donation.province) {
                        exceptProvinces.push(sender.sender_id)

                        let xx = {

                            'latitude': sender.lat,
                            'longitude': sender.lng,
                            'name': sender.name,
                            'address': sender.name,
                            'phoneNo': sender.phone_number,
                            additionalInformation: sender.additional_information,
                            'image': sender.additional_information.image ? sender.additional_information.image : null,
                            sender: true
                        };

                        xn.push(setPropertiesMarker(xx, iconCloseBook));

                    }

                })



                let MarkerIcon = {
                    'imagesUrl': '/images/buku_tertutup.png',
                    'className': 'senderCluster',
                    'imageClassName': 'senderCusterImage'
                }
                let el = <MarkerCluster
                    setIds={(id) => {
                        // console.log('setIds', id)
                        senderIds.push(id)
                    }}
                    setMap={setMap}
                    map={map}
                    sender={true} key={'sender' + index} markers={xn} markerIcon={MarkerIcon} />

                arr_sender.push(el)
            }

        });
        setExceptProvince(exceptProvinces);



        receiverDonation.forEach((donation, index) => {
            let xn = []

            donation.data.forEach(function (x) {
                let address = x.address;

                let xx = {
                    'latitude': address.latitude,
                    'longitude': address.longitude,
                    'name': x.full_name,
                    'address': address.detail,
                    'image': '',
                    'phoneNo': x.phone_number,
                    'noOfBooks': 0
                };
                // let cl = setPropertiesMarker(xx, iconOpenBook);
                let cl = {
                    position: { lng: xx.longitude, lat: xx.latitude },
                    text: '',
                    icon: iconOpenBook,
                    onClick: () => {
                        setSelectedReceiverId(x.id)
                        setDetail(xx)
                    }
                };
                if (allYears) {
                    xn.push(cl);
                } else {
                    if (moment(x.created_at).get('year') == year) {
                        xn.push(cl);
                    }

                }
            })

            // console.log('xn.length', xn.length)

            let MarkerIcon = {
                'imagesUrl': '/images/buku_terbuka.png',
                'className': 'receiverCluster',
                'imageClassName': 'receiverClusterImage'
            }

            let el = <MarkerCluster
                setIds={(id) => {
                    // console.log('setIds', id)

                    receiverIds.push(id)
                }}
                setMap={setMap}
                key={'receiver' + index} markers={xn} markerIcon={MarkerIcon} />

            arr_receiver.push(el)
        });


        setReceiverClusterGroup(arr_receiver)
        setDonationClusterGroup(arr_sender)


        let arr_province = [
            'Aceh',
            'Bali',
            'Bangka Belitung',
            'Banten',
            'Bengkulu',
            'Daerah Istimewa Yogyakarta',
            'Gorontalo',
            'Daerah Khusus Ibukota Jakarta',
            'Jambi',
            'Jawa Barat',
            'Jawa Tengah',
            'Jawa Timur',
            'Kalimantan Barat',
            'Kalimantan Selatan',
            'Kalimantan Tengah',
            'Kalimantan Timur',
            'Kalimantan Utara',
            'Kepulauan Riau',
            'Lampung',
            'Maluku Utara',
            'Maluku',
            'Nusa Tenggara Barat',
            'Nusa Tenggara Timur',
            'Papua Barat',
            'Papua',
            'Riau',
            'Sulawesi Barat',
            'Sulawesi Selatan',
            'Sulawesi Tengah',
            'Sulawesi Tenggara',
            'Sulawesi Utara',
            'Sumatera Barat',
            'Sumatera Selatan',
            'Sumatera Utara'
        ];

        let total_donation = 0;

        let all_data = [];

        arr_province.forEach((province) => {
            setLoadingExpandRanking(true);
            let dataDir = require(`../data/ranking/${province}.json`);
            let obj = {};
            Object.keys(dataDir).forEach(function (key) {
                if (allYears) {
                    Object.assign(obj, dataDir[key]);
                } else {
                    Object.assign(obj, dataDir[year]);
                }

            });
            let sum = 0;
            let sum_receiver = 0;
            let sum_accepted_donation = 0;
            for (var el in obj) {
                if (obj.hasOwnProperty(el)) {
                    sum += parseFloat(obj[el]);
                }
            }



            acceptedDonation?.forEach((y) => {
                if (y.sender_province == province) {
                    if (allYears) {
                        sum_accepted_donation += 1
                    } else {
                        if (moment(y.created_at).get('year') == year) {
                            sum_accepted_donation += 1
                        }
                    }
                }
            });

            exportedDonation.donation?.forEach((x) => {

                if (x.receiver.provName == province) {
                    if (allYears) {
                        sum_receiver += 1;
                    } else {
                        if (x.year == year) {
                            sum_receiver += 1;
                        }
                    }

                }
            })

            if (exportedDonation.donation && acceptedDonation) {
                setLoadingExpandRanking(false)
            }

            total_donation += sum + sum_accepted_donation;
            let newObj = {
                'province': province,
                'total': sum + sum_accepted_donation,
                'total_receiver': sum_receiver
            }

            all_data.push(newObj)
        });
        let arrs = [];
        let mvp_donation = all_data.reduce((p, c) => p.total > c.total ? p : c);
        let lowest_donation = all_data.reduce((p, c) => p.total < c.total ? p : c);
        let mvp_receiver = all_data.reduce((p, c) => p.total_receiver > c.total_receiver ? p : c);

        let lowest_receiver = all_data.reduce((p, c) => p.total_receiver < c.total_receiver ? p : c);
        let list_low_receiver_province = [];
        let list_low_donation_province = [];
        all_data?.forEach((x) => {
            if (x.total_receiver <= lowest_receiver.total_receiver) {
                list_low_receiver_province.push(x.province)
            }
            if (x.total <= lowest_donation.total) {
                list_low_donation_province.push(x.province)
            }
        })

        let new_obj_low_receiver = {
            'province': list_low_receiver_province.join(", "),
            'provinceArr': list_low_receiver_province,
            'total_receiver': lowest_receiver.total_receiver,
            'total': lowest_receiver.total
        }


        let new_obj_low_donation = {
            'province': list_low_donation_province.join(", "),
            'provinceArr': list_low_donation_province,
            'total_receiver': lowest_donation.total_receiver,
            'total': lowest_donation.total
        }

        setRankLowDonation(new_obj_low_donation)
        setRankTotalDonation(total_donation)
        setRankMvpDonation(mvp_donation)
        setRankMvpReceiverDonation(mvp_receiver)
        setRankLowReceiverDonation(new_obj_low_receiver)

    }, [senderDonation, exportedDonation, isSenderShown, isReceiverShown, year, allYears])

    function clearMap() {
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".flightRoutes"));

    }

    function clearReceiverLayers() {
        let noOfLayer = 0
        console.log(map)
        map.eachLayer(function (layer) {
            if (layer['_icon'] && layer['_icon'].outerHTML.includes('buku_terbuka')) {
                map.removeLayer(layer)
            }
        });
    }

    const setPropertiesMarker = (value, markerIcon) => {
        if (value) {
            return {
                position: { lng: value.longitude, lat: value.latitude },
                text: '',
                icon: markerIcon,
                onClick: () => {
                    // console.log('value', value)
                    setDetail(value)
                }
            };
        }

    }

    useEffect(() => {
        if (!isSenderShown) {
            // console.log('cha', donationClusterGroup)
        }
    }, [isSenderShown, isReceiverShown])

    useEffect(() => {
        clearMap()

    }, [exportedDonation, year, allYears])



    const handleSubmitBookForm = async () => {
        if (base64Images.length === 0) {
            alert('Harap unggah minimal 1 sampul buku')
        } else if (!trackingNumber) {
            alert('Harap mengisi nomor resi')
        } else if (!parseInt(trackingNumber)) {
            alert('Nomor resi tidak boleh berisikan huruf, spasi atau spesial karakter.')
        } else {
            setLoading(true)

            try {
                console.log('receiver select', selectedReceiverId);
                const result = await donationModel.create({
                    receiver_id: selectedReceiverId,
                    sender_id: user.id,
                    photo_urls: JSON.stringify(base64Images),
                    message,
                    tracking_number: trackingNumber
                })

                setTrackingNumber("")
                setDonateBookVisible(false)
                setMessage("")
                setBase64Images([])
                setLoading(false)
                alert('Terima kasih telah mengirimkan buku Anda. Data akan ditampilkan pada peta apabila buku telah sampai tujuan.')

            } catch (e) {
                setLoading(false)
                alert('Terjadi kesalahan')

            }
        }
    }


    return (
        <>
            <Header
                hideSearchBar={isDonateBookVisible || detail || isInstructionVisible || isSidebarShown}
                receivers={receivers}
                noOfPendingDonation={noOfPendingDonation}
                setDetail={setDetail}
                setPosition={setPosition}
                setSelectedReceiverId={setSelectedReceiverId}
                isSidebarShown={isSidebarShown}
                setSidebarShown={setSidebarShown}
                user={user}
            />

            <div>
                <Dialog
                    open={isDonateBookVisible}
                    onClose={() => {
                        if (!isLoading) {
                            setDonateBookVisible(false)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Form Pengiriman Buku
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{ marginBottom: 30, color: 'black' }}>
                            Harap isi form berikut sebagai informasi kepada Simpul Pustaka.
                        </DialogContentText>

                        <DialogContentText id="alert-dialog-description">
                            <Form.Group controlId="exampleForm.ControlTextarea1"
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label style={{ fontSize: '.9em' }}>Unggah Sampul Buku (maks. 5)</Form.Label>

                                <div className={classes.root}>
                                    <input
                                        accept="image/*"
                                        onChange={e => {
                                            const base64ImagesTemp = [...base64Images];

                                            if (base64ImagesTemp.length === 5) {
                                                alert('Maksimal 5 sampul buku yang dapat diunggah')
                                            } else {
                                                getBase64(e.target.files[0], (base64) => {
                                                    base64ImagesTemp.push(base64)

                                                    setBase64Images(base64ImagesTemp)
                                                })
                                            }
                                        }}
                                        className={classes.input}
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" color="primary" component="span">
                                            Unggah
                                        </Button>
                                    </label>

                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {
                                            base64Images.map((image, idx) => {
                                                return (
                                                    <div style={{ position: 'relative' }}>
                                                        <div style={{ position: 'absolute', top: 0, right: 15 }}>
                                                            <AiOutlineClose
                                                                onClick={() => {
                                                                    const base64ImagesTemp = [...base64Images];

                                                                    base64ImagesTemp.splice(idx, 1);

                                                                    setBase64Images(base64ImagesTemp)
                                                                }}
                                                                color={'white'} size={20} style={{ cursor: 'pointer' }} />
                                                        </div>

                                                        <img src={image} style={{
                                                            width: 150,
                                                            height: 150,
                                                            objectFit: 'contain',
                                                            backgroundColor: '#121212',
                                                            marginRight: 15,
                                                            marginBottom: 15
                                                        }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Form.Group>
                        </DialogContentText>

                        <DialogContentText id="alert-dialog-description">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label style={{ fontSize: '.9em' }}>Nomor Resi Pos Indonesia</Form.Label>
                                <Form.Control
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            handleSubmitBookForm()
                                        }
                                    }}
                                    value={trackingNumber}
                                    onChange={(e) => {
                                        setTrackingNumber(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </DialogContentText>

                        <DialogContentText id="alert-dialog-description" style={{ marginTop: 20 }}>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label style={{ fontSize: '.9em' }}>Pesan (Opsional)</Form.Label>
                                <Form.Control
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            handleSubmitBookForm()
                                        }
                                    }}
                                    as="textarea" rows={3}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </DialogContentText>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 25, marginBottom: 10 }}>
                            {!isLoading &&
                                <Button
                                    onClick={() => {
                                        setDonateBookVisible(false)
                                        setMessage("")
                                        setBase64Images([])
                                        setTrackingNumber("")
                                    }}
                                    style={{ fontWeight: '700', minWidth: 100, marginRight: 10 }}
                                    className={classes.button}
                                >
                                    Batal
                                </Button>
                            }

                            <Button
                                disabled={isLoading}
                                onClick={handleSubmitBookForm}
                                variant="contained"
                                color="primary"
                                style={{ fontWeight: '700', minWidth: 100 }}
                                className={classes.button}
                            >
                                {isLoading ?
                                    <Spinner animation="border" size="sm"
                                        style={{ marginTop: 4, marginBottom: 4 }} /> :
                                    "Kirim"}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

                <Dialog
                    maxWidth={'lg'}
                    open={isInstructionVisible}
                    onClose={() => {
                        setInstructionVisible(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ overflow: 'hidden', padding: windowWidth >= 993 ? undefined : 0 }}>
                        <Row>
                            <Col md={12} lg={6} style={{ order: windowWidth >= 993 ? 0 : 1 }}>
                                <Scrollbars style={{ width: '100%', height: windowWidth >= 993 ? 500 : '36vh' }}>

                                    <DialogTitle id="alert-dialog-title">
                                        Sepertinya anda tertarik terhadap program Pustaka Bergerak!
                                    </DialogTitle>

                                    <DialogContentText id="alert-dialog-description" style={{ padding: '16px 24px' }}>
                                        Berikut adalah syarat pengiriman paket FCL.
                                    </DialogContentText>

                                    <DialogContentText id="alert-dialog-description"
                                        style={{ marginLeft: 15, padding: '16px 24px', paddingTop: 0 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                            <div style={{ marginRight: 10 }}>
                                                1.
                                            </div>
                                            Paket hanya berisi buku dan alat-alat tulis (diajurkan terkemas dalam
                                            kotak plastik tembus pandang yang mudah jadi rak buku).
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                            <div style={{ marginRight: 10 }}>
                                                2.
                                            </div>
                                            Data pengirim dan/atau penerima telah terdaftar di database yang
                                            dipegang oleh PT Pos Indonesia.
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                            <div style={{ marginRight: 10 }}>
                                                3.
                                            </div>
                                            Setiap donatur hanya dapat mengirim 1 paket donasi berat maksimal 10
                                            kg.
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                            <div style={{ marginRight: 10 }}>
                                                4.
                                            </div>
                                            Donasi dikirim melalui kantor pos (termasuk agen pos yang memenuhi
                                            syarat) di seluruh Indonesia.
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                            <div style={{ marginRight: 10 }}>
                                                5.
                                            </div>
                                            Mencantumkan kata pengenal #INDONESIAMAJU
                                        </div>
                                    </DialogContentText>
                                </Scrollbars>
                            </Col>

                            <Col xs={12} lg={6} style={{ order: windowWidth >= 993 ? 1 : 0, width: '100%' }}>
                                <img src={'/images/foto-syarat.jpg'} style={{
                                    width: '100%',
                                    marginBottom: 15,
                                    borderRadius: 5,
                                    objectFit: 'cover',
                                    maxHeight: windowWidth >= 993 ? undefined : 400
                                }} />
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <div style={{
                            marginLeft: 15,
                            marginRight: 15,
                            marginBottom: 15,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                <Button
                                    style={{ fontWeight: '700' }}
                                    onClick={() => {
                                        setInstructionVisible(false)
                                    }} color="primary">
                                    Mengerti
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>

                <LocationDetailSidebar
                    allYears={allYears}
                    year={year}
                    isSender={user.loginAs === 'sender'}
                    donateBookAction={() => {
                        setDetail(null)
                        setDonateBookVisible(true)
                    }}
                    detail={detail} setDetail={setDetail} />

                <Map center={position} zoom={5} style={mapStyle} maxZoom={20}>
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />



                    {
                        exportedDonation.donationRoutes?.filter(function (value, index, self) {
                            return value !== null && index === self.findIndex((t) => (
                                (t.sender_province === value.sender_province && t.receiver_province === value.receiver_province)
                            ))
                        }

                        ).map(function (donation, idx) {
                            if (idx === 0) {
                                clearMap()
                            }
                            if (allYears) {
                                return <FlightCluster indexClass={idx} changeYear={changeYear} flightClusterData={[[parseFloat(donation.sender_province_lat), parseFloat(donation.sender_province_long)], [parseFloat(donation.receiver_province_lat), parseFloat(donation.receiver_province_long)]]} />

                            } else {
                                if (donation.year == year) {
                                    return <FlightCluster indexClass={idx} changeYear={changeYear} flightClusterData={[[parseFloat(donation.sender_province_lat), parseFloat(donation.sender_province_long)], [parseFloat(donation.receiver_province_lat), parseFloat(donation.receiver_province_long)]]} />
                                }
                            }



                        })
                    }


                    {
                        isSenderShown &&
                        donationClusterGroup.map((value) => {
                            return value;
                        })
                    }
                    {
                        isReceiverShown &&
                        receiverClusterGroup.map((value) => {
                            return value;
                        })
                    }
                    {
                        isSenderShown && exportedDonation.senders?.map((sender) => {
                            if (!exceptProvince.includes(sender.sender_id)) {

                                return (
                                    <Marker
                                        onClick={() => {
                                            setDetail({
                                                sender: true,
                                                image: sender.additional_information.image,
                                                name: sender.name,
                                                additionalInformation: sender.additional_information
                                            })
                                        }}
                                        position={[sender.lat, sender.lng]} icon={iconCloseBook}>
                                    </Marker>
                                )
                            } else {
                                return null
                            }


                        })
                    }

                </Map>

                {/* <Header
                receivers={receivers}
                noOfPendingDonation={noOfPendingDonation}
                setDetail={setDetail}
                setPosition={setPosition}
                setSelectedReceiverId={setSelectedReceiverId}
                isSidebarShown={isSidebarShown}
                setSidebarShown={setSidebarShown}
            /> */}

                <DonationInfo
                    hide={isDonateBookVisible || detail || isInstructionVisible || isSidebarShown}
                    windowWidth={windowWidth}
                    loadingExpandRanking={loadingExpandRanking}
                    rankTotalDonation={rankTotalDonation}
                    rankMvpDonation={rankMvpDonation}
                    rankMvpReceiverDonation={rankMvpReceiverDonation}
                    rankLowDonation={rankLowDonation}

                    rankLowReceiverDonation={rankLowReceiverDonation}
                    allYears={allYears}
                    year={year}
                    shownInfoDonation={shownInfoDonation}
                    setShownInfoDonation={setShownInfoDonation}

                ></DonationInfo>
                <LegendInfo
                    hide={isDonateBookVisible || detail || isInstructionVisible || isSidebarShown}
                    windowWidth={windowWidth}
                    shownLegendDonation={shownLegendDonation}
                    setShownLegendDonation={setShownLegendDonation}
                    allYears={allYears}
                    year={year}
                ></LegendInfo>


                <div style={{
                    position: 'absolute',
                    top: 80,
                    right: 0,
                    zIndex: isDonateBookVisible || detail || isInstructionVisible || isSidebarShown ? 0 : 9999999999,
                    padding: 15,
                    paddingLeft: 30,
                    display: 'flex',
                    flexDirection: windowWidth > 600 ? 'row' : 'column',
                    alignItems: 'center',
                }}>
                    <div
                        onClick={() => {
                            if (yearsChoice[0] !== 2017) {
                                const yearsChoiceTemp = yearsChoice.map(year => year - 1);

                                setYearsChoice(yearsChoiceTemp)
                            }
                        }}
                        style={{ marginBottom: windowWidth > 600 ? 0 : 10, backgroundColor: 'white', width: 26, height: 26, marginRight: windowWidth > 600 ? 15 : 10, borderRadius: 13, display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: yearsChoice[0] !== 2017 ? 1 : .3 }}>
                        {windowWidth > 600 ? <BiChevronLeft /> : <BiChevronUp />}
                    </div>
                    <Button
                        onClick={() => {
                            setAllYears(true)
                        }}
                        variant={allYears ? "contained" : "containedSecondary"} color="primary"
                        style={{ marginRight: 10, marginBottom: windowWidth > 600 ? 0 : 10 }}>
                        {'Semua'}
                    </Button>
                    {
                        yearsChoice.map((btnYear) => {
                            return (
                                <Button
                                    onClick={() => {
                                        if (year !== btnYear) {
                                            clearReceiverLayers()
                                            setAllYears(false)
                                            setYear(btnYear)
                                        }
                                    }}
                                    variant={!allYears && year === btnYear ? "contained" : "containedSecondary"} color="primary"
                                    style={{ marginRight: 10, marginBottom: windowWidth > 600 ? 0 : 10 }}>
                                    {btnYear}
                                </Button>
                            )
                        })
                    }

                    <div
                        onClick={() => {
                            if (yearsChoice[yearsChoice.length - 1] !== 2045) {
                                const yearsChoiceTemp = yearsChoice.map(year => year + 1);

                                setYearsChoice(yearsChoiceTemp)
                            }
                        }}
                        style={{ backgroundColor: 'white', width: 26, height: 26, marginLeft: windowWidth > 600 ? 5 : 0, marginRight: windowWidth > 600 ? 0 : 10, borderRadius: 13, display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: yearsChoice[yearsChoice.length - 1] !== 2045 ? 1 : .3 }}>
                        {windowWidth > 600 ? <BiChevronRight /> : <BiChevronDown />}
                    </div>
                </div>

                <div style={{
                    position: 'absolute',
                    bottom: 20,
                    left: 0,
                    zIndex: isDonateBookVisible || detail || isInstructionVisible || isSidebarShown ? 0 : 9999999999,
                    padding: 15,
                    paddingLeft: 30,
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Button
                        onClick={() => {
                            setReceiverShown(!isReceiverShown)
                        }}
                        variant={isReceiverShown ? "contained" : "containedSecondary"} color="primary"
                        style={{ marginRight: 10 }}>
                        Simpul Pustaka
                    </Button>

                    <Button
                        onClick={() => {
                            setSenderShown(!isSenderShown)
                        }}
                        variant={isSenderShown ? "contained" : "containedSecondary"} color="primary"
                        style={{ marginRight: 10 }}>
                        Pengirim
                    </Button>

                </div>


                {/*<div style={{ width: 400, position: 'absolute', top: 0}}>*/}

                {/*</div>*/}
            </div>
        </>
    );
}
