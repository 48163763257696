import React, {useState} from "react";
// import "./custom-marker.css";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom";
import MapPage from "./pages/MapPage";
import LoginPage from "./pages/LoginPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import MyDonationPage from "./pages/MyDonationPage";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import RegisterPage from "./pages/RegisterPage";
import ProfilePage from "./pages/ProfilePage";

import "./App.css";
import FCLPage from "./pages/FCLPage";
import ChangePasswordPage from "./pages/ChangePswdPage";

const App = () => {

    const theme = createMuiTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#67904A',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <HashRouter>
                {/*<BrowserRouter>*/}
                <Switch>
                    <Redirect exact from='/' to='/simpul'/>
                    <Route exact path="/simpul" component={MapPage}/>
                    <Route exact path="/masuk" component={LoginPage}/>
                    <Route exact path="/daftar" component={RegisterPage}/>
                    <Route exact path="/status-pengiriman" component={MyDonationPage}/>
                    <Route exact path="/profil" component={ProfilePage}/>
                    <Route exact path="/fcl" component={FCLPage}/>
                    <Route exact path="/ubah-kata-sandi" component={ChangePasswordPage}/>
                </Switch>
            </HashRouter>
        </ThemeProvider>
    )

};

export default App;
