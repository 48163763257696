import ApiRequest from "../util/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Receiver {

    getAll = async () => {
        return await ApiRequest.set("/v1/receivers", "GET");
    }

    update = async (id, body) => {
        return await ApiRequest.set(`/v1/receivers/${id}`, "PUT", body);
    }

    updatePassword = async (id, body) => {
        return await ApiRequest.set(`/v1/receivers/update-password/${id}`, "PUT", body);
    }
}
