import Map from "../reusable/Map";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fullLogo from "../img/logo-full.jpg";
import { Form, Spinner, Alert } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from '@material-ui/core/Button';
import Palette from "../util/Palette";
import User from "../models/User";
import { useHistory } from "react-router-dom";
import Sender from "../models/Sender";
import Receiver from "../models/Receiver";
import { AiOutlineClose } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import { FaInfoCircle } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import {BiLeftArrowAlt} from "react-icons/all";
import swal from 'sweetalert';

export default function ChangePasswordPage(props) {
    const [isLoading, setLoading] = useState(false);

    const history = useHistory()

    const userModel = new User();
    const senderModel = new Sender();
    const receiverModel = new Receiver();

    const [updateData, setUpdateData] = useState({});

    const [address, setAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [provinces, setProvinces] = useState([])
    const [base64Images, setBase64Images] = useState([])
    const [prevProvince, setPrevProvince] = useState(null);

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProvinces()
            tokenLogin()

        } else {
            history.push("/masuk")
        }
    }, [])

    const getProvinces = async () => {
        const result = await userModel.getAllProvince();
        setProvinces(result)
    }

    const directChangePassword = () => {
        history.push("/change-passowrd")
    }

    const tokenLogin = async () => {
        try {
            const user = await userModel.tokenLogin();

            console.log('user', user)
            setPrevProvince(user.province)
            setAddress(user.address.detail)
            setLatitude(user.address.latitude)
            setLongitude(user.address.longitude)
            setUpdateData(user)
            provinces.forEach(value => {
                if (value.id == user.province) {
                    user.province = value.name
                }
            });
            if (user.photo_urls) {
                setBase64Images(JSON.parse(user.photo_urls))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleUpdate = async () => {
        if (updateData.newPassword !== updateData.repeatNewPassword) {
            swal("Ubah Kata Sandi Gagal!", 'Konfirmasi kata sandi dan kata sandi tidak cocok.', "error");

            return;
          }
        if (!updateData.oldPassword || !updateData.repeatNewPassword || !updateData.newPassword) {
            swal("Ubah Kata Sandi Gagal!", 'Harap mengisi form dengan lengkap.', "error");
        } else {
            setLoading(true)

            try {
                let result;

                if (updateData.loginAs === 'sender') {
                    result = await senderModel.updatePassword(updateData.id, updateData)
                }else if(updateData.loginAs === 'receiver'){
                    result = await receiverModel.updatePassword(updateData.id, updateData)
                }

                setLoading(false)

                if (result.success) {
                    swal("Ubah Kata Sandi Berhasil!", 'Kata sandi berhasil diperbarui.', "success").then(value => {
                        history.push("/")
                    });
                }

                console.log(result)
            } catch (e) {
                setLoading(false)
                if (e.msg) {
                    swal("Ubah Kata Sandi Gagal!", e.msg, "error");
                } else {
                    swal("Ubah Kata Sandi Gagal!", 'Kesalahan terjadi.', "error");
                }
                console.log(e)
            }
        }
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
    }));

    const classes = useStyles();

    return (
        <Container style={{
            minHeight: "100vh",
            paddingTop: 30,
            paddingBottom: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col md={5}>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 20,
                        paddingBottom: 50
                    }}>
                        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                            <IconButton onClick={() => {
                                history.push('/profil')
                            }}>
                                <BiLeftArrowAlt color={'grey'}
                                                style={{cursor: 'pointer'}}
                                                size={30}
                                />
                            </IconButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 20,
                            paddingTop: 0,
                            width: "90%"
                        }}>
                            <img
                                style={{
                                    width: "100%",
                                    marginTop: 15,
                                    marginBottom: 30,
                                }}
                                src={fullLogo} />

                            <Form style={{
                                width: "100%",
                            }}>
                                <Form.Group controlId="formBasicPassword">

                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        onChange={evt => setUpdateData({
                                            ...updateData,
                                            oldPassword: evt.target.value
                                        })} type="password" placeholder="Masukan Password Lama" />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">

                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        onChange={evt => setUpdateData({
                                            ...updateData,
                                            newPassword: evt.target.value
                                        })} type="password" placeholder="Masukan Password Baru" />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">

                                    <Form.Control
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                handleUpdate()
                                            }
                                        }}
                                        onChange={evt => setUpdateData({
                                            ...updateData,
                                            repeatNewPassword: evt.target.value
                                        })} type="password" placeholder="Ulangi Password Baru" />
                                </Form.Group>
                            </Form>

                            <Button
                                variant="contained" color="primary" component="span"
                                onClick={() => {
                                    handleUpdate()
                                }}
                                style={{
                                    width: '100%'
                                }}
                                disabled={isLoading}
                                type="submit">
                                {isLoading ?
                                    <Spinner animation="border" size="sm"
                                        style={{ marginTop: 4, marginBottom: 4 }} /> :
                                    "Simpan"}
                            </Button>

                        </div>


                    </Card>

                </Col>
            </Row>
        </Container>
    )
}
