import ApiRequest from "../util/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Util {

    uploadImage = async (image, purpose) => {

        let formData = new FormData();

        formData.append('upload', image, image.name);

        let response = await ApiRequest.setMultipart(`/v1/upload-image/${purpose}`, "POST", formData);

        // GlobalData.user = userData
        //
        // if (userData.token) {
        //     GlobalData.token = userData.token
        // }

        return response

    }
}