import React, { useEffect, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BiBox, BiBook, BiPhone } from "react-icons/bi";
import { MdMessage, MdPhone } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "@material-ui/core/Button";
import noImgPlaceholder from "../img/no-img-placeholder.jpg";
import { Scrollbars } from 'react-custom-scrollbars';
import Donation from "../models/Donation";
import CircularProgress from '@material-ui/core/CircularProgress';
export default function LocationDetailSidebar(props) {
    const { detail, setDetail, isSender, year, allYears } = props;

    const donationModel = new Donation();
    const [totalDonation, setTotalDonation] = useState(0);
    const [ranking, setRanking] = useState({})
    const [loadingRanking, setLoadingRanking] = useState(false);
    useEffect(() => {
        console.log('props', props)
        //   let data_dir = require(`../data/ranking/${props.detail.address}.json`);
        if (detail && detail.additionalInformation) {
            if (allYears) {
                setLoadingRanking(true)
                donationModel.getRankingAll().then(value => {
                    if (value) {

                        let totalDonationTemp = 0;
                        let cons = value.map(function (x) {
                            if (props.detail.address == x.province_name) {
                                return {
                                    [x.name]: x.total
                                }
                            }

                        })
                        let obj = cons.reduce(function (acc, val) {
                            return Object.assign(acc, val);
                        }, {});

                        for (const val of value) {
                            if (props.detail.address == val.province_name) {
                                totalDonationTemp += val.total;
                            }

                        }
                        let dataDir = require(`../data/ranking/${detail.additionalInformation.ranking_file}`);
                        let allData = {};

                        Object.keys(dataDir).forEach(function (key) {
                            Object.assign(allData, dataDir[key]);
                        });

                        Object.keys(allData).forEach(key => {
                            const datum = allData[key]
                            totalDonationTemp += datum;
                        })

                        const merged = { ...obj, ...allData };

                        var sortable = [];
                        for (var x in merged) {
                            sortable.push([x, merged[x]]);
                        }

                        sortable.sort(function (a, b) {
                            return b[1] - a[1];
                        });
                        let sortedx_obj = {};





                        setTotalDonation(totalDonationTemp)
                        setRanking(Object.fromEntries(sortable));
                        setLoadingRanking(false)
                    } else {
                        setTotalDonation(0)
                        setRanking({})
                        setLoadingRanking(false)
                    }

                });

            } else {
                try {
                    const data = require(`../data/ranking/${detail.additionalInformation.ranking_file}`)
                    console.log(props)


                    if (data[year]) {
                        let totalDonationTemp = 0;

                        Object.keys(data[year]).forEach(key => {

                            const datum = data[year][key]

                            totalDonationTemp += datum;
                        })
                        setLoadingRanking(false)
                        setTotalDonation(totalDonationTemp)
                        setRanking(data[year]);
                    } else {
                        setLoadingRanking(true)
                        donationModel.getRankingByYear(props.year).then(value => {
                            if (value) {

                                let totalDonationTemp = 0;
                                let cons = value.map(function (x) {

                                    if (props.detail.address == x.province_name) {
                                        return {
                                            [x.name]: x.total
                                        }
                                    }

                                })
                                let obj = cons.reduce(function (acc, val) {
                                    return Object.assign(acc, val);
                                }, {});

                                for (const val of value) {
                                    if (props.detail.address == val.province_name) {
                                        totalDonationTemp += val.total;
                                    }

                                }

                                setTotalDonation(totalDonationTemp)
                                setRanking(obj);
                                setLoadingRanking(false)
                            } else {
                                setTotalDonation(0)
                                setRanking({})
                                setLoadingRanking(false)
                            }

                        });


                    }
                } catch (e) {
                    setTotalDonation(0)
                    setRanking({})
                    setLoadingRanking(false)
                }
            }

        } else {
            setTotalDonation(0)
            setRanking({})
            setLoadingRanking(false)
        }
    }, [detail])


    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            width: detail ? '50vh' : 0,
            zIndex: 999999999999999,
            display: 'flex',
            flexDirection: 'row',
            transition: 'width .25s'
        }}>

            <div
                onClick={() => setDetail(null)}
                style={{
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    alignSelf: 'flex-start',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
                    marginTop: 20,
                    width: 23,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}>
                <AiFillCaretRight size={12} color={'#8c8c8c'} />
            </div>
            <div style={{ backgroundColor: 'white', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', height: '100vh', flex: 1 }}>

                <Carousel showArrows={true} showThumbs={false}>
                    {
                        !detail?.image ?
                            <div style={{ backgroundColor: '#f8f4f4' }}>
                                <img src={noImgPlaceholder} style={{ height: 250, width: '100%', objectFit: 'contain' }} />
                            </div> :
                            detail?.image?.map(src => {
                                return (
                                    <div style={{ backgroundColor: '#121212' }}>
                                        <img src={src} style={{ height: 250, width: '100%', objectFit: 'contain' }} />
                                    </div>
                                )
                            })

                    }


                </Carousel>





                <div style={{ fontSize: '1.375rem', color: 'black', padding: '16px 24px 16px' }}>
                    {detail?.name}
                </div>

                {!detail?.sender && isSender &&
                    <Button
                        onClick={props.donateBookAction}
                        variant="contained" color="primary"
                        style={{ marginLeft: 24, marginBottom: 24, fontSize: '.8em', fontWeight: '700' }}>
                        Kirim Buku
                    </Button>
                }

                <div style={{ borderBottom: '1px solid #E8EAED' }} />

                {detail?.additionalInformation ? null :
                    <div style={{
                        padding: '8px 24px 8px 0',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 17
                    }}>
                        <div style={{ height: 30, width: 30, marginLeft: 24, marginRight: 24 }}>
                            <GoLocation color={'rgb(104, 146, 76)'} size={21} />
                        </div>
                        <div style={{ flex: 1, whiteSpace: 'pre-wrap' }}>
                            {detail?.address}
                        </div>
                    </div>
                }

                {loadingRanking ? <CircularProgress style={{
                    margin: 'auto',
                    marginTop: 50,
                    textAlign: 'center',
                    display: 'block'
                }} disableShrink /> : ''}
                {
                    !loadingRanking ?
                        <>
                            <div style={{
                                padding: '8px 24px 8px 0',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 5
                            }}>
                                <div style={{ height: 30, width: 30, marginLeft: 24, marginRight: 24 }}>
                                    <BiBox color={'rgb(104, 146, 76)'} size={21} />
                                </div>
                                <div style={{ flex: 1 }}>
                                    {detail?.totalDonation ? detail?.totalDonation : detail?.additionalInformation ? totalDonation : 0} {detail?.additionalInformation ? 'Donasi' : 'Pengiriman'}
                                </div>


                            </div>
                            
                        </>


                        : ''
                }



                {props.detail?.phoneNo &&
                    <div style={{
                        padding: '8px 24px 8px 0',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <div style={{ height: 30, width: 30, marginLeft: 24, marginRight: 24 }}>
                            <MdPhone color={'rgb(104, 146, 76)'} size={21} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {props.detail?.phoneNo}
                        </div>
                    </div>
                }

                {props.detail?.message &&
                    <div style={{
                        padding: '8px 24px 8px 0',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <div style={{ height: 30, width: 30, marginLeft: 24, marginRight: 24 }}>
                            <MdMessage color={'rgb(104, 146, 76)'} size={21} />
                        </div>
                        <div style={{ flex: 1 }}>
                            {props.detail?.message}
                        </div>
                    </div>
                }

                {totalDonation > 0 ?
                    <>
                        <div style={{ marginTop: 20, borderBottom: '1px solid #E8EAED' }} />

                        <div style={{
                            padding: '8px 24px 8px 0',
                            alignItems: 'center',
                            marginTop: 5,
                            marginLeft: 24,
                        }}>
                            <div style={{ color: '#222', fontSize: 18, marginBottom: 15 }}>
                                Daftar Donasi
                            </div>

                            <Scrollbars style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                                {
                                    Object.keys(ranking).map((key, idx) => {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginBottom: 15
                                            }}>
                                                <div style={{ flex: 1, fontSize: 14 }}>
                                                    {idx + 1}. {key}
                                                </div>

                                                <div style={{
                                                    fontSize: 16,
                                                    color: 'rgb(104, 146, 76)',
                                                    fontWeight: '600'
                                                }}>
                                                    {ranking[key]} Donasi
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Scrollbars>

                        </div>
                    </> : null
                }
            </div>
        </div>
    )
}
