import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Scrollbars} from 'react-custom-scrollbars';
import fullLogo from "../img/logo-full.jpg";
import Button from "@material-ui/core/Button";
import {IoMdExit} from "react-icons/io";
import {HiOutlineLogin} from "react-icons/hi";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {GiHamburgerMenu} from "react-icons/gi";
import Donation from "../models/Donation";
import Autosuggest from "react-autosuggest";
import {MdClose} from "react-icons/md";
import Header from "../reusable/Header";
import User from "../models/User";

export default function FCLPage() {
    const images = [
        "/images/fcl/1.jpg",
        "/images/fcl/2.jpeg",
        "/images/fcl/3.jpeg",
        "/images/fcl/4.jpeg",
        "/images/fcl/5.jpeg",
        "/images/fcl/6.jpeg",
        "/images/fcl/7.jpeg",
        "/images/fcl/8.jpeg",
        "/images/fcl/9.jpeg",
        "/images/fcl/10.jpeg",
        "/images/fcl/11.jpeg",
        "/images/fcl/12.jpeg",
        "/images/fcl/13.jpeg",
        "/images/fcl/14.jpeg",
        "/images/fcl/15.jpeg",
        "/images/fcl/16.jpeg",
        "/images/fcl/17.jpeg",
    ]

    const [isSidebarShown, setSidebarShown] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [noOfPendingDonation, setNoOfPendingDonation] = useState(0);
    const history = useHistory()
    const donationModel = new Donation();
    const userModel = new User();
    const [user, setUser] = useState({})

    const tokenLogin = async () => {
        try {
            const user = await userModel.tokenLogin();

            console.log('user', user)

            setUser(user)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            tokenLogin()
            getNoOfPendingDonation()
        }
    }, [])

    const getNoOfPendingDonation = async () => {
        try {
            const result = await donationModel.getPending();

            setNoOfPendingDonation(result[0].count_pending_donations)
        } catch (e) {
            console.log(e)
        }
    }

    const handleResize = () => {
        console.log('window.innerWidth', window.innerWidth)

        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Container fluid style={{padding: 0}}>
            <Header
                user={user}
                noOfPendingDonation={noOfPendingDonation}
                isSidebarShown={isSidebarShown}
                setSidebarShown={setSidebarShown}
            />

            <Container>
                <Row>
                    <Col md={12} lg={5} style={{order: windowWidth >= 993 ? 0 : 1}}>
                        <DialogTitle id="alert-dialog-title">
                            Semangat Free Cargo Literacy
                        </DialogTitle>

                        <DialogContentText id="alert-dialog-description"
                                           style={{marginLeft: 15, padding: '16px 24px', paddingTop: 0}}>
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                style={{width: '100%', height: windowWidth >= 993 ? '70vh' : 1200}}>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        1.
                                    </div>
                                    Free Cargo Literacy adalah gerakan warga yang difasilitasi pemerintah untuk
                                    memarakkan kreatifitas dan solidaritas nasional.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        2.
                                    </div>
                                    Setiap buku yang terkirim adalah amanah untuk mencerdaskan kehidupan
                                    bangsa.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        3.
                                    </div>
                                    Semua pihak yang terlibat adalah saudara yang saling bantu untuk
                                    mengerahkan pengetahuan dan mengangkat mutu sumber daya manusia.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        4.
                                    </div>
                                    Seluruh kegiatan pengerahan pengetahuan ini dilakukan secara transparan,
                                    jujur dan bertanggungjawab.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        5.
                                    </div>
                                    Relawan yang menerima buku wajib memanfaatkan buku-buku tersebut
                                    sebaik mungkin dan menunjukkan penggunaannya kepada publik, khususnya
                                    dermawan buku. Posting kegiatan pemanfaatan buku adalah bentuk
                                    pertanggungjawaban penerimaan buku sekaligus kampanye gerakan
                                    pengerahan pengetahuan.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        6.
                                    </div>
                                    Dermawan yang mengirimkan buku diharapkan dapat menunjukkan dan
                                    menjelaskan buku-buku yang dikirim, dan bersedia memantau pemanfaatan
                                    buku-buku tersebut. Dermawan yang terbaik berusaha jadi kawan diskusi
                                    pemanfaatan buku-buku yang dikirim.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        7.
                                    </div>
                                    FCL adalah milik bersama, dan dipersembahkan terutama buat masyarakat
                                    yang kurang beruntung, yang terpencil secara geografis dan terkucil secara
                                    sosial-ekonomi. Penyalah gunaan FCL adalah pelanggaran kepentingan bersama
                                    yang secara khusus menyerobot kepentingan warga yang kurang beruntung.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        8.
                                    </div>
                                    Penyalahgunaan fasilitas FCL dapat menganggu bahkan membuat macet FCL.
                                    Jika ada masalah pengiriman yang timbul, sebaiknya diselesaikan dengan
                                    semangat kekeluargaan sambil mengupayakan perbaikan yang terus menerus.
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                                    <div style={{marginRight: 10}}>
                                        9.
                                    </div>
                                    Hari pengiriman buku gratis sehari dalam sebulan adalah hari pesta rakyat
                                    pengerahan pengetahuan. Dianjurkan kepada semua pihak yang terlibat untuk
                                    merayakan festival ini secara kreatif dan penuh solidaritas, yang menunjukkan
                                    kekayaan kultural Indonesia dan harapan besar untuk mewujudkan Indonesia
                                    Maju
                                </div>
                            </Scrollbars>
                        </DialogContentText>
                    </Col>

                    <Col xs={12} lg={7}
                         style={{
                             order: windowWidth >= 993 ? 1 : 0,
                             width: '100%',
                             position: 'relative',
                             marginTop: windowWidth >= 993 ? 30 : 0,
                         }}>
                        <div style={{top: 0, left: 0, right: 0, marginTop: 30}}>
                            <Carousel showStatus={false} infiniteLoop={true} autoPlay={true}>
                                {
                                    images.map(img => {
                                        return (
                                            <div style={{
                                                borderRadius: 5,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <img src={img}
                                                     style={{width: '100%', maxHeight: 400, objectFit: 'contain'}}/>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
