import ApiRequest from "../util/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class User {

    login = async (body) => {

        let response = await ApiRequest.set("/v1/user/login", "POST", body);

        // GlobalData.user = userData
        //
        // if (userData.token) {
        //     GlobalData.token = userData.token
        // }

        return response

    }

    tokenLogin = async (body) => {

        let response = await ApiRequest.set("/v1/user/tokenLogin", "POST");
        
        // GlobalData.user = userData
        //
        // if (userData.token) {
        //     GlobalData.token = userData.token
        // }

        return response

    }

    register = async (body) => {
        let response = await ApiRequest.set("/v1/user/register", "POST", body);

        return response;
    }

    getAllProvince = async () => {
        return await ApiRequest.set("v1/getAllProvince", "GET");
      }
}