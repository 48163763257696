import {useEffect, useState} from "react";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import {useLeaflet} from "react-leaflet";
import {customMarker} from "./constants";
import './custom-marker.css';

export default function MarkerCluster(props) {
    const {setMap, setIds} = props;

    const [clusterIcon, setClusterIcon] = useState(props.markerIcon)

    const mcg = L.markerClusterGroup({
        maxClusterRadius: 200,
        iconCreateFunction: function (cluster) {
            const iconCloseBook = new L.Icon({
                iconUrl: '/images/buku_tertutup.png',
                iconSize: new L.Point(60, 75),
            });
            let markers = cluster.getAllChildMarkers();

            let html = '<div style="position: relative"><img class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive '+ clusterIcon.imageClassName + '" src=' + clusterIcon.imagesUrl +'> <div class="'+ clusterIcon.className + `" style="margin-left: ${ markers.length > 99 ? '-px' : markers.length > 9 ? '-1px' : '0px'};">` + markers.length + '</div></div> ';

            // if(props.sender) html = '<div style="position: relative"><img class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive '+ clusterIcon.imageClassName + '" src=' + clusterIcon.imagesUrl +'> <div class="'+ clusterIcon.className + `">` + markers.length + '</div></div> ';
            return L.divIcon({ html: html, className: 'mycluster', iconSize: L.point(70, 75) });
        },
    });
    const markers = props.markers

    // console.log('isi dari props : ',clusterIcon)
    const {map} = useLeaflet();

    useEffect(() => {
        if (map) {
            setMap(map)
        }
    }, [map])

    useEffect(() => {

        markers.forEach(({position, text, icon, onClick}) =>
            L.marker(new L.LatLng(position.lat, position.lng), {
                icon: icon
            })
                .addTo(mcg)
                // .bindPopup(text)
                // .off("click")
                .on('click', (e) => {

                    onClick();
                })
        );
        map.addLayer(mcg);

        // map.d

    }, [markers, map]);

    return null;
}
